import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TableToolBarComponent } from './table-tool-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material.module';
import { ButtonTableToolBarModule } from '../button-table-tool-bar/button-table-tool-bar.module';

@NgModule({
	imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        MaterialModule,
        ButtonTableToolBarModule
    ],
	declarations: [TableToolBarComponent],
	exports: [TableToolBarComponent],
})
export class TableToolBarModule {}
