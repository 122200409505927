import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { domainModel } from 'src/app/models/domain/domain.model';
import { TableRowUnSelectEvent } from 'primeng/table';


@Component({
  selector: 'app-support-domain-view',
  templateUrl: './support-domain-view.component.html',
  styleUrls: ['./support-domain-view.component.scss']
})
export class SupportDomainViewComponent implements OnInit{

  domainList!: domainModel[];
  loadingDomains: boolean = true;
  selectedDomain: domainModel[] = [];

    constructor(
        private AdminService: AdminService,
    ) {}


  ngOnInit() {

    this.AdminService.getAllDomains().subscribe((result: any) => {

      this.domainList = result.items.map((item: any) => ({
        domainId: item['Domain ID'], // Accessing property with bracket notation
        token: item['Token'],
        type: item['Type'],
        api: item['api'],
        invoiceCredits: item['invoiceCredits'],
        invoices: item['invoices'],
        plenionB2C: item['plenionB2C'],
        plenionWebServices: item['plenionWebServices'],
        portal: item['portal'],
        userPermissions: item['userPermissions'],
      })); 

      this.loadingDomains = false;
    })
  };

  onRowSelect(event: any) {
    let myURL = window.location.origin;
		this.AdminService.redirectToDomain(event.data.domainId).subscribe((result: any) => {
      let targetURL = myURL + '/login?idToken=' + result['token'] + '&idDomain=' + event.data.domainId + '&site=adportal';
      window.open(targetURL, '_blank');
    });
  }

  onRowUnselect($event: TableRowUnSelectEvent) {} 
}
