import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { InvoiceInfo } from 'src/app/models/user/invoiceinfo.model';
import { AdminService } from 'src/app/services/admin.service';
import { MainContentService } from 'src/app/services/main-content.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-invoices-view',
  templateUrl: './invoices-view.component.html',
  styleUrls: ['./invoices-view.component.scss']
})
export class InvoicesViewComponent {
    unsubscriber$: Subject<any> = new Subject();

    loadingInvoicesInfo: boolean = true;
    customerInfo!: InvoiceInfo;
    emailsAllowed: string[] = [];
    newEmail: string = "";
    creditsToAdd: number = 0;
    isInvoiceEnabled: boolean = false;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private adminService: AdminService,
        private mainContentService: MainContentService,
        private commonService: CommonService
    ) {}

    ngOnInit(): void {
        this.loadingInvoicesInfo = true;
        this.isInvoiceEnabled = this.commonService.isSupport === 'true' && this.commonService.isInvoiceEnabled === 'true' ? true : false;

        this.activeRoute.paramMap.pipe(takeUntil(this.unsubscriber$)).subscribe((params: ParamMap) => {
            this.adminService.getInvoicesInfo().subscribe((result: any) => {
                this.customerInfo = result["values"];                                

                this.emailsAllowed = this.customerInfo.allowedEmails;
                this.loadingInvoicesInfo = false;
            }) 
        })
    }

    addAllowedEmail() {
        if(this.newEmail != "") {
            this.adminService.addAllowedEmail(this.newEmail).subscribe((result: any) => {
               this.emailsAllowed.push(this.newEmail);
               this.newEmail = ""; 
            }) 
        }

    }

    removeAllowedEmail(email: string) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService.removeAllowedEmail(email).subscribe(
                    {
                        complete: () => {
                            this.emailsAllowed = this.emailsAllowed.filter((allowedEmail) => allowedEmail != email);
                            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User removed from allowed emails list' });
                        },
                        error: () => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        }
                    }
                ) 
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        })

        // this.adminService.removeAllowedEmail(email).subscribe((result: any) => {
        //     this.emailsAllowed = this.emailsAllowed.filter((allowedEmail) => allowedEmail != email);
        // }) 
    }

    addCredits(defaultCredits?: number) {
        if(defaultCredits) {
            this.creditsToAdd += defaultCredits;
        } else {
            if(this.creditsToAdd > 0) {
                this.adminService.addInvoiceCredits(this.creditsToAdd).subscribe(
                    {
                        complete: () => {
                            var oldInvoicesCredits: number = this.customerInfo.credit;
                            var newCredits: number = +oldInvoicesCredits;
                            newCredits += this.creditsToAdd;
                            this.customerInfo.credit = newCredits;
                            this.creditsToAdd = 0;
                            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Credits added' });
                        },
                        error: (err: any) => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        },
                    }
                ) 
            }
        }
    }
}
