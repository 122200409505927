import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { formatTypeNames } from 'src/app/utils/functions';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { GlobalConstants } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-types-view',
  templateUrl: './types-view.component.html',
  styleUrls: ['./types-view.component.scss']
})
export class TypesViewComponent {
    @ViewChild('Werknemer') werknemer!: any;
    @ViewChild('Voertuig') voertuig!: any;
    @ViewChild('Machine') machine!: any;

    @ViewChildren('typesCheckbox') typesCheckbox!: QueryList<any>;
	unsubscriber$: Subject<any> = new Subject();
    
    allTypes: any;
    types: any;
    resources: any;
    isLoadingTypes: boolean = true;
    isLoadingResources: boolean = true;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private adminService: AdminService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
    ) {}

    ngOnInit(): void {
        this.allTypes = GlobalConstants.typeOrder;

        this.adminService.getDomainTypes().subscribe((result: any) => {
            if(result.types) this.types = result.types;
            else this.types = [];

            this.isLoadingTypes = false;
        }) 

        this.adminService.getDomainResources().subscribe((result: any) => {
            if(result.resources) this.resources = result.resources;
            else this.resources = [];

            this.isLoadingResources = false;
        }) 
    }

    updateValues(): void {
        let newTypesArray: string[] = [];
        let newResourcesArray: string[] = [];

        // We get the types states from the list
        this.typesCheckbox!.forEach((type) => {
            if(type.nativeElement.checked) {
                newTypesArray.push(type.nativeElement.name);
            }
        });

        // We get the resources states from the list
        if(this.werknemer.nativeElement.checked)
            newResourcesArray.push("Werknemer")

        if(this.voertuig.nativeElement.checked)
            newResourcesArray.push("Voertuig")

        if(this.machine.nativeElement.checked)
            newResourcesArray.push("Machine")


        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService.updateDomainTypes(newTypesArray).subscribe(
                    {
                        complete: () => {
                            this.adminService.updateDomainResources(newResourcesArray).subscribe(
                                {
                                    complete: () => {
                                        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User updated' });
                                    },
                                    error: () => {
                                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                                    }
                                }
                                
                            )
                        },
                        error: () => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        }
                    }
                );
                
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        });
    }

    formatTypeNames(type: string) {
        return formatTypeNames(type);
    }
}
