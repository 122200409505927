<div class="enable-b2c-modal">
    <h1 mat-dialog-title><b>Enable access to B2C app</b></h1>
    <mat-dialog-content class="mat-typography">
        <p>{{"You are going to modify the access to the contact with email" | translate }} <b>{{data.contact.email}}</b> 
            {{ "to be able to use the Plenion Touch app." | translate}}</p>
        <b>{{"Are you sure you want to change the access?" | translate}}</b>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button (click)="onBack()" mat-button mat-dialog-close>Go Back</button>
        <button *ngIf="!data.contact.isEnabled" (click)="onSubmit()" mat-button>Disable access</button>
        <button *ngIf="data.contact.isEnabled" (click)="onSubmit()" mat-button>Enable
            access</button>
        </mat-dialog-actions>
</div>