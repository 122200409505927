import { Component, Input } from '@angular/core';

export interface ToolBarItem {
	label: string;
	icon?: string;
	disabled?: boolean;
	align: string;
	action: () => void;
}
@Component({
  selector: 'app-table-tool-bar',
  templateUrl: './table-tool-bar.component.html',
  styleUrls: ['./table-tool-bar.component.scss']
})
export class TableToolBarComponent  {
    @Input() buttons: Array<any> = [];
	@Input() buttonsMenu: Array<any> = [];
	@Input() mobile: boolean = false;
	ngOnChanges() {
		this.updateButtons();
	}

	buttonsLeft: Array<any> = [];
	buttonsRight: Array<any> = [];
	buttonsCenter: Array<any> = [];

	constructor() {}

	ngOnInit(): void {
		this.updateButtons();
	}

	updateButtons() {
		this.buttonsLeft = this.buttons?.filter((b) => b.align === 'left');
		this.buttonsRight = this.buttons?.filter((b) => b.align === 'right');
		this.buttonsCenter = this.buttons?.filter((b) => b.align === 'center');
	}
}
