import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../core/auth/auth-service.service";
import { environment } from "src/environments/environment";
import { capitalize } from "../utils/functions";
import { tap } from "rxjs";
import { User } from "../models/auth/user.model";
import { PortalUser } from "../models/user/user.model";
import { TouchConfig } from "../models/touch/touch.model";


@Injectable({
	providedIn: 'root',
})
export class DownloadService {
    private apiUrl: String = environment.apiUrl;
	private apiKey: String = environment.apiPlenionKey;
	private apiPlenion: String = environment.apiPlenion;
    private apiCloud: String = environment.apiUrlHost;
    private apiB2c: String = environment.b2cUrl;
    
    token: string = "";

    constructor(private http: HttpClient, private translate: TranslateService, private authService: AuthService) {
		this.token = this.authService.getToken();
	}

    getDownloadFiles() {
        const url =  `${this.apiPlenion}/download/checkSharedFiles?&key=` + this.apiKey + '&auth=' + this.token;

        return this.http.get<JSON>(url);
    }

    enableDisableDownload(downloadKey: string) {
        const url =  `${this.apiPlenion}/download/enableDisableSharedFile?key=` + this.apiKey + '&auth=' + this.token + '&downloadToken=' + downloadKey;

        return this.http.get<any>(url);
    }

    openFileDownload(fileToken: string) {

        const url =  `${this.apiPlenion}/download/metadata/` + fileToken + `/?auth=` + this.token + '&key=' + this.apiKey;

        return this.http.get<any>(url);
    }

    getLogDownload() {
        const url =  `${this.apiPlenion}/download/listDownloadLogs?key=` + this.apiKey + '&auth=' + this.token;

        return this.http.get<any>(url);
    }

    getFileLogDownload(fileID: string) {
        const url =  `${this.apiPlenion}/download/listDownloadLogs?key=` + this.apiKey + '&auth=' + this.token + '&fileID=' + fileID;

        return this.http.get<any>(url);
    }

}