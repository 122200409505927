import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AdminService } from 'src/app/services/admin.service';
import { StatusService } from 'src/app/services/status.service';
import { ToolBarItem } from 'src/app/components/table-tool-bar/table-tool-bar.component';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { GlobalConstants } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss']
})
export class UsersViewComponent implements OnInit {
	loggedIn: boolean = false;
	selectedUser: any[] = [];
	users: any[] = [];
	loadingUsers: boolean = true;
	canEdit: boolean = false;
	canDelete: boolean = false;
	userTypes: string = "";
	userResoruces: string = "";

	toolBarButtons: ToolBarItem[] = [];
	toolBarButtonsMenu: ToolBarItem[] = [];
	authService: any;

	gc: any[] =  GlobalConstants.resourcesCategories;

	constructor(
		private router: Router,
		private activeRoute: ActivatedRoute,
		private appService: AppService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private adminService: AdminService,
	) {
		
	}
	
	async ngOnInit(): Promise<void> {
		this.selectedUser = []
		this.canDelete = false;
		this.canEdit = false;

		this.loadUsers();
		this.updateToolBar();
	}

	loadUsers(): void {
		this.loadingUsers = true;
		this.users = [];
		this.selectedUser = [];

		this.adminService.getDomainUsers().subscribe((result: any) => {
			this.users = result.values;

			this.users.forEach(user => {
				if(!user.Types || user.Types.length === 0)
					user.Types = 'Default';

				if(!user.Resources || user.Resources.length === 0) {
					user.Resources = 'Default';
				} else {					
					let myResources: any[] = [];
					user.Resources.forEach((resource: any) => {
						let found = false;
						for(let i=0; i<this.gc.length && !found; i++) {
							if(this.gc[i]['label'] === resource) {
								resource = this.gc[i][localStorage.getItem('language')!];
								myResources.push(resource);
								found = true;
							}
						}

						user.Resources = myResources;
					})
					
				}
			});

			this.loadingUsers = false;
		})
	}

	updateToolBar(): void {
		this.toolBarButtons = [
			{
				label: 'Refresh',
				icon: 'refresh',
				align: 'left',
				action: () => {
					this.loadUsers();
				},
			},
			{
				label: 'Add',
				icon: 'add',
				align: 'right',
				action: () => {
					this.router.navigate([`users/adduser`])
				},
			},
			{
				label: 'Edit',
				icon: 'create',
				align: 'right',
				disabled: !this.canEdit,
				action: () => {
					this.router.navigate([`users/editUser/${this.selectedUser[0].UserID}`])
				},
			},
			{
				label: 'Delete',
				icon: 'delete',
				align: 'right',
				disabled: !this.canDelete,
				action: () => {
					this.deleteUser(this.selectedUser[0].UserID, this.selectedUser[0].Email);
				},
			}
			
		];

		this.toolBarButtonsMenu = [
			{
				label: 'Refresh',
				icon: 'refresh',
				align: 'left',
				action: () => {

				},
			}
		];
	}

	onRowSelect(event: any) {		
		if(this.selectedUser.length == 1) {
			this.canEdit = true;
		}  else {
			this.canEdit = false;
		}
		this.canDelete = true;
		this.updateToolBar();
	  }
	  
	onRowUnselect(event: any) {
		const index = this.selectedUser.indexOf(event.data);
		if(index !== -1) {
			this.selectedUser.splice(index, 1);
		}
		if(this.selectedUser.length == 1) {
			this.canEdit = true;
		} else {
			this.canEdit = false;
		}

		if(this.selectedUser.length == 0) {
			this.canDelete = false;
		}

		this.updateToolBar();
	}	  

	deleteUser(userId: string, userEmail: string) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to delete the user ' + userId + '?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.adminService.deleteUser(userId, userEmail).subscribe(
					{
						complete: () => {
							this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User deleted succesfully' });

							setTimeout( () => {
								this.loadUsers();
								this.updateToolBar();
							}, 2000);
						},
						error: () => {
							this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
						}
					}
				)
			},
			reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
		});
	}

	getArrayFormatted(array: any) {
		if(array && array !== 'Default')
			return array.join(', ');
		else
			return 'Default'
		
	}
}
