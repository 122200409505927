import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { MainContentService } from 'src/app/services/main-content.service';
import { CommonService } from 'src/app/services/common.service';
import { RoutesInfo } from 'src/app/models/user/routesinfo.model';
import { formatDate } from 'src/app/utils/functions';

@Component({
  selector: 'app-routes-view',
  templateUrl: './routes-view.component.html',
  styleUrls: ['./routes-view.component.scss']
})
export class RoutesViewComponent {
    unsubscriber$: Subject<any> = new Subject();

    loadingRoutesInfo: boolean = true;
    customerInfo!: RoutesInfo;
    creditsToAdd: number = 0;
    isRoutesEnabled: boolean = false;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private adminService: AdminService,
        private mainContentService: MainContentService,
        private commonService: CommonService
    ) {}

    ngOnInit(): void {
        this.loadingRoutesInfo = true;
        
        this.isRoutesEnabled = this.commonService.isRoutesEnabled ? true : false;

        this.activeRoute.paramMap.pipe(takeUntil(this.unsubscriber$)).subscribe((params: ParamMap) => {
            this.adminService.getRoutesInfo().subscribe((result: any) => {
                this.customerInfo = result["values"];                                

                this.loadingRoutesInfo = false;
            }) 
        })
    }

    addCredits(defaultCredits?: number) {
        if(defaultCredits) {
            this.creditsToAdd += defaultCredits;
        } else {
            if(this.creditsToAdd > 0) {
                this.adminService.addRoutesCredits(this.creditsToAdd).subscribe(
                    {
                        complete: () => {
                            var oldRoutesCredits: number = this.customerInfo.credit;
                            var newCredits: number = +oldRoutesCredits;
                            newCredits += this.creditsToAdd;
                            this.customerInfo.credit = newCredits;
                            this.creditsToAdd = 0;
                            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Credits added' });
                        },
                        error: (err: any) => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        },
                    }
                ) 
            }
        }

    }

    formatDate(date: string) {
        return formatDate(date);
    }
}
