import { Injectable, NgZone, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MainContentService } from 'src/app/services/main-content.service';
import { ItemResponse, Item } from 'src/app/models/item/item.model';
import { fromObjectToArray } from '../../utils/mapping';
import { Router } from '@angular/router';
import { ActionsService } from 'src/app/services/actions.service';

//Variables para el picker.
declare var gapi: any;
declare var google: any;

@Injectable({
	providedIn: 'root',
})
export class GooglePickerService {
	language: string = 'en';
	maxitems: number = 15;
	languageTitle: string = 'Select up to ' + this.maxitems + ' items to upload';
	searchAddResults: Item[] = [];
	searchAddResultsType: string = "";
	idFolder: string = "";

	constructor(
		private http: HttpClient,
		private actionsService: ActionsService,
		private projectsService: MainContentService,
		private router: Router,
		private ngZone: NgZone,
	) {}

	private apiUrl: String = environment.apiUrl;
	private folderId: string = "";
	private folderName: string = "";
	private folderType: string = "";
	private subfolderId: string = "";
	private token: string = "";
	private invoicing: boolean = false;
	@Output() uploadData: EventEmitter<Object> = new EventEmitter<Object>();

	// OBTENER IDIOMA PARA GOOGLE PICKER
	getLanguageForPicker(language: string) {
		this.language = language;
		this.changeHeaderLangue();
	}

	setInvoicing(invoicing: boolean) {
		this.invoicing = invoicing;
	}

	// CAMBIAR IDIOMA DEL HEADER DEL PICKER
	changeHeaderLangue() {
		if (this.language === 'en') {
			this.languageTitle = 'Select up to ' + this.maxitems + ' items to upload';
		}
		if (this.language === 'fr') {
			this.languageTitle = "Sélectionnez jusqu'à " + this.maxitems + ' éléments à télécharger';
		}
		if (this.language === 'nl') {
			this.languageTitle = 'Selecteer maximaal ' + this.maxitems + ' items om te uploaden';
		}
	}

	//LLAMADA ACCESS TOKEN
	accessToken(token: string) {
		return this.http.get(`${this.apiUrl}portal/v0.1/drive/accessToken`).pipe(
			map((response: any) => {
				return response['accessToken'];
			}),
		);
	}

	//LLAMADA SUBIR ARCHIVOS
	saveFiles(token: string, name: string, id: string, type: string, array: string, subfolderId: string): Observable<string> {
		var parameters: any;
		if (id == null) {
			//Estamos en una subcarpeta
			parameters = {
				token: token,
				subfolderId: subfolderId,
				filesId: array,
			};
		} else {
			//Estamos en una carpeta o en la búsqueda.
			parameters = {
				token: token,
				folderName: name,
				folderId: id,
				folderType: type,
				filesId: array,
			};
		}

		return this.http.post(`${this.apiUrl}portal/v0.1/drive/files/upload`, parameters).pipe(
			map((response: any) => {
				if (response['OK'] == null) return 'We have problems with the server';
				return response['OK'];
			}),
		);
	}

	//LLAMADA DEL PICKER
	onClicked(token: string, folderId: string, folderName: string, folderType: string, subfolder: string) {
		this.accessToken(token).subscribe((response) => {
			if (response) {
				this.folderId = folderId;
				this.folderName = folderName;
				this.folderType = folderType;
				this.token = token;
				this.subfolderId = subfolder;

				this.createPicker(response); //Cargamos la API y pasamos a la parte javascript
			}
		});
	}

	pickerLoad(oauth: any) {
		gapi.load('picker', this.checkCreate(oauth));
	}

	checkCreate(oauth: any) {
		//Comprueba que se ha cargado la libreria del picker y llama a la funcion para crearlo
		if (google.picker) this.createPicker(oauth);
	}

	createPicker(oauth: any) {
		this.ngZone.runOutsideAngular(() => {
			if (google.picker == undefined) {
				this.pickerLoad(oauth);
			}
			if (oauth) {
				var viewFolders = new google.picker.DocsView(google.picker.ViewId.DOCS);
				viewFolders.setIncludeFolders(true);
				viewFolders.setSelectFolderEnabled(true);
				viewFolders.setOwnedByMe(true);

				var picker = new google.picker.PickerBuilder()
					.disableFeature(google.picker.Feature.NAV_HIDDEN)
					.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
					.enableFeature(google.picker.Feature.MINE_ONLY)
					.setLocale(this.language)
					.setMaxItems(this.maxitems)
					.addView(new google.picker.DocsUploadView().setParent('root'))
					.setTitle(this.languageTitle)
					.setOAuthToken(oauth)
					.setCallback(this.pickerCallback.bind(this))
					.build();
				picker.setVisible(true);
			}
		});
	}

	// redireccionar a la carpeta donde se han subido los documentos del picker
	showTypeSearch() {
		this.projectsService.searchAddResults.subscribe((response: ItemResponse) => {
			this.searchAddResults = fromObjectToArray(response);
			if (this.searchAddResults[0] !== undefined) {
				if (this.searchAddResults[0].Type !== undefined) {
					this.searchAddResultsType = this.searchAddResults[0].Type.toLowerCase();
				}
			}
		});
	}

	// toma el id de la carpeta para introducirlo en el router navigate
	getFolderId(id: string) {
		this.idFolder = id;
	}

	// navega a la carpeta donde se han subido los documentos
	navigate() {
		this.ngZone.run(() => {
			if (this.searchAddResults[0] !== undefined) {
				this.router.navigate([`/${this.searchAddResultsType}/${this.idFolder}/files`]);
			}
		});
	}

	pickerCallback(data: any) {
		this.ngZone.run(() => {
			var array = '';

			if (data.action == google.picker.Action.PICKED) {
				var documentsSelected = data[google.picker.Response.DOCUMENTS];
				for (var i = 0; i < documentsSelected.length; i++) {
					if (array.length > 0) {
						array += ',';
					}
					array += documentsSelected[i].id;
				}
			}

			if (array.length > 0) {
				//Hacemos la petición al servidor para guardarlos en la carpeta correspondiente.
				this.saveFiles(this.token, this.folderName, this.folderId, this.folderType, array, this.subfolderId).subscribe((response) => {
					if (!this.invoicing) {
						this.showTypeSearch();

						if (this.folderType !== null && this.folderName !== null) {
							this.navigate();
						}
						this.actionsService.set({ type: 'picker', status: 'updated', message: 'File uploaded' });
						this.uploadData.emit(data[google.picker.Response.DOCUMENTS]);
					} else {
						this.actionsService.set({
							type: 'notification',
							status: 'updated',
							message: 'Uploaded',
						});

						this.uploadData.emit(data[google.picker.Response.DOCUMENTS]);
					}
				});
			}
		});
	}
}
