import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../core/auth/auth-service.service";
import { environment } from "src/environments/environment";
import { capitalize } from "../utils/functions";
import { tap } from "rxjs";
import { User } from "../models/auth/user.model";
import { PortalUser } from "../models/user/user.model";
import { TouchConfig } from "../models/touch/touch.model";

@Injectable({
	providedIn: 'root',
})
export class AdminService {
	private apiUrl: String = environment.apiUrl;
	private apiKey: String = environment.apiPlenionKey;
	private apiPlenion: String = environment.apiPlenion;
    
    token: string = "";

    constructor(private http: HttpClient, private translate: TranslateService, private authService: AuthService) {
		this.token = this.authService.getToken();
	}

    getDomainUsers() {
        const url =  `${this.apiPlenion}/admin/users?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url);
	}

	getDomainUser(userId: string) {
		const url =  `${this.apiPlenion}/admin/users/user?&key=` + this.apiKey + '&auth=' + this.authService.getToken()
			+ '&id=' + userId;

		return this.http.get<JSON>(url);
	}

	getTouchUsers() {
		const url =  `${this.apiPlenion}/admin/touch?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<any>(url);
	}

	changeTouchAccess(contactId: string, contactEmail: string, customerId: string) {
		let body = {
			"email": contactEmail, 
			"customer": customerId
		}

		const url =  `${this.apiPlenion}/admin/touch/` + contactId + `?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));
	}

	setDomainUserAccess(userId: string, email: string, api: boolean, portal: boolean, admin: boolean, useDefaultTypes: boolean, useDefaultResources: boolean) {
		let body = {
			"email": email,
			"api": api,
			"portal": portal,
			"admin": admin,
			"useDefaultTypes": useDefaultTypes,
			"useDefaultResources": useDefaultResources
		}

		const url =  `${this.apiPlenion}/admin/users/` + userId + `?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));
	}

	setUserTypes(userId: string, newTypes: string[]) {
		let body = { "types": newTypes }

		const url =  `${this.apiPlenion}/admin/users/` + userId + `/usertypes?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));
	}

	setUserResources(userId: string, newResources: string[]) {
		let body = { "resources": newResources }

		const url =  `${this.apiPlenion}/admin/users/` + userId + `/userresources?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));
	}

	addUser(userId: string, userEmail: string, userPass: string, apiEnabled: boolean, portalEnabled: boolean, isAdmin: boolean){
		let body = {
			"ID": userId,
			"email": userEmail,
			"passwd": userPass,
			"api": apiEnabled,
			"portal": portalEnabled,
			"admin": isAdmin
		}
		
		const url =  `${this.apiPlenion}/admin/users/user?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.post<any>(url,body);
	}

	deleteUser(userId: string, userEmail: string) {
		let body = { 
			"idUser": userId,
			"email": userEmail
		}

		const url =  `${this.apiPlenion}/admin/users/user?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.delete<any>(url, {body: body}).pipe(tap((response) => {}));
	}

	getDomainTypes() {
		const url =  `${this.apiPlenion}/admin/types?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url);	
	}

	getDomainResources() {
		const url =  `${this.apiPlenion}/admin/resources?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url);	
	}

	updateDomainTypes(newResources: string[]) {
		let body = { "types": newResources }

		const url =  `${this.apiPlenion}/admin/types?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));	
	}

	updateDomainResources(newTypes: string[]) {
		let body = { "resources": newTypes }

		const url =  `${this.apiPlenion}/admin/resources?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));	
	}

	getInvoicesInfo() {
        const url =  `${this.apiPlenion}/admin/invoices?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url);
	}

	addAllowedEmail(email: string) {
		let body = { "email": email }

		const url =  `${this.apiPlenion}/admin/invoices?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));	
	}

	removeAllowedEmail(email: string) {
		let body = { 
			"email": email
		}

		const url =  `${this.apiPlenion}/admin/invoices?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.delete<any>(url, {body: body}).pipe(tap((response) => {}));
	}

	getTouchConfiguration() {
        const url =  `${this.apiPlenion}/admin/touch/config?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url);
	}

	updateTouchConfiguration(touchConfig: TouchConfig, myLogo: string) {
		let body = { 
			"welcomeMsg": touchConfig.welcomeMsg,
			"email": touchConfig.email,
			"fiscalInfo": touchConfig.fiscalInfo,
			"address": touchConfig.address,
			"phoneNumber": touchConfig.phoneNumber,
			"websiteURL": touchConfig.websiteURL,
			"logo": myLogo
		}

		const url =  `${this.apiPlenion}/admin/touch/config?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));	
	}

	getDocumentsLabels() {
        const url =  `${this.apiPlenion}/admin/documentslabels?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url);
	}

	addDocumentLabel(en: string, fr: string, nl: string) {
		let body = {
			"en": en,
			"fr": fr,
			"nl": nl
		}
		
		const url =  `${this.apiPlenion}/admin/documentslabels?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.post<any>(url,body);
	}

	initializeDocumentsLabels() {
		const url =  `${this.apiPlenion}/admin/documentslabels/initialize?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.post<any>(url, {});	
	}

	getMissingFolders(type: String) {
		const url =  `${this.apiPlenion}/admin/folders?&key=` + this.apiKey + '&auth=' + this.authService.getToken() + '&type=' + type;

		return this.http.get<JSON>(url, {});	
	}
	
	syncMissingFolders(type: String, folders: string[]) {
		let body = { "folders": folders }
		
		const url =  `${this.apiPlenion}/admin/folders?&key=` + this.apiKey + '&auth=' + this.authService.getToken() + '&type=' + type;

		return this.http.post<any>(url, body);	
	}

	recreateMainFolders() {
		const url =  `${this.apiPlenion}/admin/folders/recreate?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url, {});	
	}

	getAllDomains() {
		const url =  `${this.apiPlenion}/admin/support/getAllDomains?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url, {});	
	}

	redirectToDomain(domainId: string) {
		const url =  `${this.apiPlenion}/admin/support?key=` + this.apiKey + '&auth=' + this.authService.getToken() + '&domain=' + domainId;

		return this.http.post<JSON>(url, {});	
	}

	checkInvoicePermission() {
		const url =  `${this.apiPlenion}/admin/support/invoice?key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url, {});
	}

	addInvoiceCredits(credits: number) {
		const url =  `${this.apiPlenion}/admin/support/invoice?key=` + this.apiKey + '&auth=' + this.authService.getToken() + '&credits=' + credits.toString();

		return this.http.post<JSON>(url, {});
	}

	changeLabelAccess(label: any) {
		let body = {
			"labelID": label.id, 
		}

		const url =  `${this.apiPlenion}/admin/documentslabels/status?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.put<any>(url, body).pipe(tap((response) => {}));
	}

	getRoutesInfo() {
        const url =  `${this.apiPlenion}/admin/routes?&key=` + this.apiKey + '&auth=' + this.authService.getToken();

		return this.http.get<JSON>(url);
	}

	addRoutesCredits(credits: number) {
		const url =  `${this.apiPlenion}/admin/routes?key=` + this.apiKey + '&auth=' + this.authService.getToken() + '&credits=' + credits.toString();

		return this.http.post<JSON>(url, {});
	}

}