import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { MenubarModule } from 'primeng/menubar';
import { MaterialModule } from 'src/app/shared/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [RouterModule, TranslateModule, BrowserAnimationsModule, MenubarModule, MaterialModule],
	declarations: [NavbarComponent],
	exports: [NavbarComponent],
})
export class NavbarModule {}
