import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';
import { ActionsService } from 'src/app/services/actions.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-documentlabel-enable-modal',
  templateUrl: './documentlabel-enable-modal.component.html',
  styleUrls: ['./documentlabel-enable-modal.component.scss']
})
export class DocumentLabelEnableModalComponent implements OnInit {
    unsubscriber$: Subject<any> = new Subject();

	idContact: string = "";

	isEnabled: boolean = false;
	message: string = "";
	showSuccessMessage: boolean = false;
	showErrorMessage: boolean = false;
	label: any;

	successMessage: Message[] = [];
	errorMessage: Message[] = [];

	constructor(
		private adminService: AdminService,
		private actionsService: ActionsService,
		private dialogRef: MatDialogRef<DocumentLabelEnableModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { label: any },
	) {}

	ngOnInit(): void { 
		this.label = this.data.label;
		
		this.isEnabled = this.label.enabled;	
		this.successMessage = [
            { severity: 'success', summary: 'Success', detail: '✔️ Availability of Document label updated' },
        ];
		this.errorMessage = [
            { severity: 'error', summary: 'Error', detail: '❌ There was an error' },
        ];
	}

    onSubmit() {		
		this.adminService.changeLabelAccess(this.label).subscribe(
		{
			complete: () => {
				this.actionsService.set({ type: 'b2c-user-create', status: 'updated', message: '✔️ User access to B2C app updated'})
				this.dialogRef.close('success')
			},
			error: () =>  {
				this.actionsService.set({ type: 'notification', status: 'none', message: '❌ There was an error'});
				this.dialogRef.close('back');
			}
		});
	}

	onBack() {
		this.dialogRef.close("back");
	}
}
