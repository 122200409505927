import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material.module';
import { ButtonTableToolBarComponent } from './button-table-tool-bar.component';

@NgModule({
	imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        MaterialModule
    ],
	declarations: [ButtonTableToolBarComponent],
	exports: [ButtonTableToolBarComponent],
})
export class ButtonTableToolBarModule {}
