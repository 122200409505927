<div class="tool-bar">
	<div class="menu-desktop" *ngIf="!mobile">
		<div class="button-group button-group-left">
			<app-button-table-tool-bar
				*ngFor="let button of buttonsLeft"
				[icon]="button.icon"
				label="{{ button.label | translate }}"
				[disabled]="button.disabled"
				(click)="button.action()"
			></app-button-table-tool-bar>
		</div>
		<div class="button-group button-group-center">
			<app-button-table-tool-bar
				*ngFor="let button of buttonsCenter"
				[icon]="button.icon"
				label="{{ button.label | translate }}"
				[disabled]="button.disabled"
				(click)="button.action()"
			></app-button-table-tool-bar>
		</div>
		<div class="button-group button-group-right">
			<app-button-table-tool-bar
				*ngFor="let button of buttonsRight"
				[icon]="button.icon"
				label="{{ button.label | translate }}"
				[disabled]="button.disabled"
				(click)="button.action()"
			></app-button-table-tool-bar>
		</div>
	</div>

	<div class="menu-mobile" *ngIf="mobile">
		<app-button-table-tool-bar [matMenuTriggerFor]="menu" [icon]="'menu'" [label]="'Menu'"></app-button-table-tool-bar>

		<mat-menu #menu="matMenu">
			<button mat-menu-item *ngFor="let button of buttonsMenu" (click)="button.action()">
				<mat-icon>{{ button.icon }}</mat-icon>
				<span>{{ button.label | translate }}</span>
			</button>
		</mat-menu>
	</div>
</div>
