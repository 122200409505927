<div class="panel-content-table" >
    <p-table 
        #dt
        [value]="downloads" 
        [tableStyle]="{ 'min-width': '50rem' }"
        [globalFilterFields]="['senderEmail', 'sharedEmails', 'fileName', 'fileID']"
        (onRowSelect)="onRowSelect($event)"
		(onRowUnselect)="onRowUnselect($event)"
        dataKey="sharedDate"
        [rowExpandMode]="'single'"
    >
        <ng-template pTemplate="caption"> 
            <div class="flex">
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search keyword" />
                </span>
                <div *ngIf="!confirmationWindow" class="confirmation-button">
                    <p-toast></p-toast>
                    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
                    <app-table-tool-bar [buttons]="toolBarButtons" [buttonsMenu]="toolBarButtonsMenu" [mobile]="false">

                        <!-- <p-button (click)="confirmationDownloads()" icon="pi pi-check" label="Sync"></p-button> -->
                    </app-table-tool-bar>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="width: 4rem"></th>
                <th>{{"Sender Email" | translate}}</th>
                <th>{{"Shared Emails" | translate}}</th>
                <th>{{"File name" | translate}}</th>
                <th>{{"File ID" | translate}}</th>
                <th>{{"Mimetype" | translate}}</th>
                <th>{{"Language" | translate}}</th>
                <th>{{"Shared Date" | translate}}</th>
                <th>{{"Expiration Date" | translate}}</th>
                <th>{{"Is Enabled" | translate}}</th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                    <p-columnFilter type="boolean" field="isEnabled"></p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-download let-expanded="expanded">
            <tr>
                <td>
                    <p-tableCheckbox [value]="download"></p-tableCheckbox>
                </td>
                <td>
                    <button type="button" (click)="loadLogs(download.fileID)" pButton pRipple [pRowToggler]="download" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td>{{ download.senderEmail }}</td>
                <td>{{ download.sharedEmails }}</td>
                <td class="td-linkToOpenWindow" (click)="openDownload(download.itemId)">{{ download.fileName }}</td>
                <td>{{ download.fileID }}</td>
                <td>{{ download.mimeType }}</td>
                <td>{{ download.language }}</td>
                <td>{{ download.sharedDate }}</td>
                <td>{{ download.expirationDate }}</td>
                <td style="text-align: center">{{ download.isEnabled ? '✔' : '❌' }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-logDownload>
            <tr>
                <td colspan="11">
                    <div class="p-3">
                        <p-table [value]="logs" dataKey="timeStamp">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>{{"Channel" | translate}}</th>
                                    <th>{{"Event" | translate}}</th>
                                    <!-- <th>{{"Expiration Date" | translate}}</th> -->
                                    <th>{{"IP" | translate}}</th>
                                    <th>{{"TimeStamp" | translate}}</th>
                                    <th>{{"Users" | translate}}</th>
                                    <th>{{"Filename" | translate}}</th>
                                    <th>{{"File ID" | translate}}</th>
                                    <th>{{"Receiver Email" | translate}}</th>
                                    <th>{{"Sender User" | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-logDownload>
                                <tr>
                                    <td>{{ logDownload.channel }}</td>
                                    <td>{{ logDownload.event }}</td>
                                    <!-- <td>{{ logDownload.expirationDate }}</td> -->
                                    <td>{{ logDownload.ip }}</td>
                                    <td>{{ logDownload.timeStamp }}</td>
                                    <td>{{ logDownload.users }}</td>
                                    <td>{{ logDownload.filename }}</td>
                                    <td>{{ logDownload.idFile }}</td>
                                    <td>{{ logDownload.receiverUserEmail }}</td>
                                    <td>{{ logDownload.senderUserId }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </td>
            </tr>
        </ng-template>
        <tr *ngIf="!loadingDownloads" colspan="3" style="padding: 0">
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </tr>
        <ng-template pTemplate="summary"></ng-template>
    </p-table>
</div>