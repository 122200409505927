import { Injectable, OnDestroy } from '@angular/core';
import { Subject, fromEvent, Observable, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export type Device = 'mobile' | 'tablet' | 'desktop';

@Injectable({
	providedIn: 'root',
})
export class StatusService {
	devices: BehaviorSubject<Device> = new BehaviorSubject<Device>(this.onResize(window.innerWidth));
	devices$: Observable<Device> = this.devices.asObservable();

	connection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!window.navigator.onLine);
	connection$: Observable<boolean> = this.connection.asObservable();

	constructor() {
		fromEvent(window, 'online').subscribe(() => this.onConnectionChange(true));

		fromEvent(window, 'offline').subscribe(() => this.onConnectionChange(false));

		fromEvent(window, 'resize')
			.pipe(debounceTime(500))
			.subscribe(() => this.devices.next(this.onResize(window.innerWidth)));

		this.onResize(window.innerWidth);
	}

	onConnectionChange(value: boolean) {
		this.connection.next(value);
	}

	onResize(width: number): Device {
		if (width > 1100) return 'desktop';
		else if (width > 576) return 'tablet';
		else return 'mobile';
	}

	isOnline() {
		return this.connection.getValue();
	}

	checkDevice() {
		return this.devices.getValue();
	}

	isMobile() {
		return this.devices.getValue() === 'mobile';
	}

	isDesktop() {
		return this.devices.getValue() === 'desktop';
	}

	isTablet() {
		return this.devices.getValue() === 'tablet';
	}
}
