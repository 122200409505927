<div class="navbar">
    <p-menubar [model]="menuItems">
        <ng-template pTemplate="start" class="navbar-start">
            <img src="../../../assets/icons/icon-128x128.png" height="40" class="navbar-start-logo"/>
            <h3 style="color: white; margin-top: 8px;">Admin - {{ companyName }}</h3>
        </ng-template>
        <ng-template pTemplate="end">
            <div id="language-dropdown" [matMenuTriggerFor]="menuLanguage">
                <span>{{ selected }}</span>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
            <mat-menu #menuLanguage="matMenu" (change)="selectLanguageHandler($event)">
                <a (click)="onChangeLanguage('en')" value="EN" mat-menu-item>
                    <span>English</span>
                </a>
                <a (click)="onChangeLanguage('fr')" value="FR" mat-menu-item>
                    <span>Français</span>
                </a>
                <a (click)="onChangeLanguage('nl')" value="NL" mat-menu-item>
                    <span>Nederlands</span>
                </a>
            </mat-menu>
            <a (click)="onLogout()" mat-menu-item id="logout-button">
				<span translate style="color: white">{{ "Log out" | translate}}</span>
			</a>
        </ng-template>
    </p-menubar>
</div>
<ng-content></ng-content>