import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';

if (environment.production) {
    enableProdMode();
}
provideAnimations(),
{
    provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
    useValue:false,
};

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
    