import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { AuthService } from '../auth/auth-service.service';
import { GooglePickerService } from 'src/app/shared/google-picker/google-picker.service';
import { ActionsService } from 'src/app/services/actions.service';
import { CommonService } from 'src/app/services/common.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    email: FormControl = new FormControl('', [Validators.required]);
	password: FormControl = new FormControl('', [Validators.required]);
	matcher = new MyErrorStateMatcher();

	notRegistered: any;
	emailParam: string | null = "";
	remember: boolean = false;
	errorMessage: string = "";
	domain_selected: string = "";
	domains: string[] | null = null;
	tokens: string[] | null = null;

	selected = 'EN';
	language: string | null = 'en';

	constructor(
		private translate: TranslateService,
		private authService: AuthService,
		private googlePickerService: GooglePickerService,
		private router: Router,
		private http: HttpClient,
		private actionsService: ActionsService,
		private commonService: CommonService,
		private cookieService: CookieService,
	) {
		this.language = localStorage.getItem('language');
		this.changeLanguage(this.language);
	}

	ngOnInit() {
		if (this.authService.getToken()) {						
			this.router.navigate(['users']);
			
		} else {
			var url = new URL(window.location.href);
			this.notRegistered = url.searchParams.get('notRegistered');
			this.emailParam = url.searchParams.get('email');
			let site = url.searchParams.get('site');
			
			if (site && site === 'support'){
				let userToken = url.searchParams.get('idToken')!;
				this.commonService.token = userToken;
				this.commonService.isAdmin = 'true';
				this.commonService.isSupport = 'true';

				this.router.navigate(['support']);

			}else if(site && site === 'adportal') {
				this.cookieService.set('LOG', 'ADPORTAL', 3600);
	
				let idDomain = url.searchParams.get('idDomain')!;
				let userToken = url.searchParams.get('idToken')!;
				this.domain_selected = idDomain;
	
				this.domains = [];
				this.tokens = [];
	
				this.domains?.push(idDomain);
				this.tokens?.push(userToken);
	
				this.commonService.token = userToken;
				this.commonService.companyName = idDomain;
	
				this.continue();
			} else {
				

				if (url.searchParams.get('domains') && url.searchParams.get('tokens')) {
					this.domains = url.searchParams.get('domains')!.split(',');
					this.tokens = url.searchParams.get('tokens')!.split(',');
					this.domains = this.domains.filter((s) => s !== '');
					this.tokens = this.tokens.filter((s) => s !== '');
				}

				if(!this.domains && !this.tokens) {
					let myCookie = this.cookieService.get('LOG');
					
					if(myCookie && (myCookie === 'Google' || myCookie === 'Office')) {						
						this.login(myCookie);
					}
				}
				
				if (this.notRegistered != null) {
					if (this.emailParam) {
						this.actionsService.set({ type: 'notification', status: 'none', message: `❌ ${this.emailParam} is not registered` });
					} else {
						this.actionsService.set({ type: 'notification', status: 'none', message: `❌ You are not registered` });
					}
				}
			}
		}

		
		

		
	}

	login(type: string) {
		this.cookieService.set('LOG', type, 3600);
		this.authService.login(type, this.remember);
	}

	submitFirebase() {
		this.errorMessage = '';
		this.authService
			.authenticateFirebase(this.email.value, this.password.value)
			.then((value: any) => {})
			.catch((value: any) => {
				this.actionsService.set({ type: 'notification', status: 'none', message: '❌ ' + value });
			});
	}

	continue() {
		let index = this.domains!.indexOf(this.domain_selected);

		this.router.navigateByUrl(`/login?token=${this.tokens![index]}`);
		window.location.href = `/login?token=${this.tokens![index]}`;
	}

	changeLanguage(language: string | null) {
		switch (language) {
			case 'en':
				this.selected = 'EN';
				this.language = 'en';
				break;
			case 'fr':
				this.selected = 'FR';
				this.language = 'fr';
				break;
			case 'nl':
				this.selected = 'NL';
				this.language = 'nl';
				break;
			default:
				this.selected = 'NL';
				this.language = 'nl';
		}

		this.translate.use(this.language);
	}

	onChangeLanguage(language: string) {
		this.translate.use(language);
		localStorage.setItem('language', language);
		this.changeLanguage(language);
		this.googlePickerService.getLanguageForPicker(this.language!);
	}
}
