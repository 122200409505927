import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Action } from 'src/app/models/action/action.model';

@Injectable({
	providedIn: 'root',
})
export class ActionsService {
	currentAction: BehaviorSubject<Action | undefined> = new BehaviorSubject<Action | undefined>(undefined);
	currentAction$: Observable<Action | undefined> = this.currentAction!.asObservable();

	constructor() {}

	set(action: Action): void {
		this.currentAction.next(action);
	}
}
