import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolBarItem } from 'src/app/components/table-tool-bar/table-tool-bar.component';
import { AdminService } from 'src/app/services/admin.service';
import { DownloadService } from 'src/app/services/download.service';
import { ConfirmEventType, MessageService, ConfirmationService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-log-download-view',
  templateUrl: './log-download-view.component.html',
  styleUrls: ['./log-download-view.component.scss']
})
export class LogDownloadViewComponent {
  logs: any[] = [];
  loadingLogs: boolean = true;
  apiKey: String = environment.apiPlenionKey;
	apiPlenion: String = environment.apiPlenion;
  defaultSortField: string = 'timeStamp';


  constructor(
    private downloadService: DownloadService,
  ) {
    
  }

  async ngOnInit():  Promise<void> {
    this.loadDownloads();
  }

  loadDownloads(): void {
    this.loadingLogs = true;
    this.logs = [];

    this.downloadService.getLogDownload().subscribe((result: any) => {
        this.logs = result.items;
        this.loadingLogs = false;   
    });
  }
}
