import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../core/auth/auth-service.service';
import { SaveTokenRequest } from 'src/app/models/firebase/firebase.model';

@Injectable({
	providedIn: 'root',
})
export class MessagingService {
	private apiUrl: String = environment.apiUrl;
	private token: any;
	currentMessage = new BehaviorSubject(null);

	constructor(
		private angularFireAuth: AngularFireAuth,
		private angularFireMessaging: AngularFireMessaging,
		private messageService: MessageService,
		private http: HttpClient,
		private authService: AuthService,
	) {
		// this.angularFireMessaging.messaging.subscribe((_messaging) => {
		// 	_messaging.onMessage = _messaging.onMessage.bind(_messaging);
		// 	_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
		// });
	}

	updateToken(userId: any, token: any) {
		this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
			this.token = this.authService.getToken();

			const url = `${this.apiUrl}portal/v0.1/mail/firebasetoken`;
			const data: SaveTokenRequest = {
				token: this.token,
				tokenFirebase: token,
			};

			if (this.token != null) this.http.put(url, data).pipe().subscribe();
		});
	}

	requestPermission(userId: any) {
		this.angularFireMessaging.requestToken.subscribe(
			(token) => {
				if (token !== null) {
					this.updateToken(userId, token);
				}
			},
			(err) => {
				//console.error('Unable to get permission to notify.');
			},
		);
	}

	isSubscribed(userId: any) {
		return this.angularFireMessaging.getToken;
	}

	receiveMessage() {
		this.angularFireMessaging.messages.subscribe((payload: any) => {
			this.currentMessage.next(payload);
			if (payload.data.classified) {
				this.messageService.add({
					key: 'email-notification',
					severity: 'info',
					summary: payload.notification.title,
					detail: payload.notification.body,
					data: {
						type: payload.data.type,
						plenionID: payload.data.plenionID,
						from: payload.data.user,
					},
				});
			} else if(payload.data.start) { // EVENT
				this.messageService.add({
					key: 'email-notification',
					severity: 'info',
					summary: payload.notification.title,
					detail: payload.notification.body,
					data: {
						start: payload.data.start,
						end: payload.data.end
					},
				});
			} else {
				this.messageService.add({
					key: 'email-notification',
					severity: 'info',
					summary: payload.notification.title,
					detail: payload.notification.body,
					data: {
						from: payload.data.user,
					},
				});
			}
		});
	}
}
