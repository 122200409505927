import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { AdminService } from 'src/app/services/admin.service';
import { DocumentLabelEnableModalComponent } from 'src/app/shared/modals/documentlabel-enable-modal/documentlabel-enable-modal.component';

@Component({
  selector: 'app-categories-view',
  templateUrl: './categories-view.component.html',
  styleUrls: ['./categories-view.component.scss']
})
export class CategoriesViewComponent {
    labels: any[] = [];
    selectedLabelId: string = "";
    loadingLabels: boolean = true;
    showInitializeButton: boolean = false;
    showSuccessMessage: boolean = false;
	showErrorMessage: boolean = false;

    successMessage: any[] = [];
	errorMessage: any[] = [];
    
    newLabel: any = {
        en: "",
        fr: "",
        nl: ""
    }

    constructor(
        private router: Router,
        private dialogRef: MatDialog,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private adminService: AdminService,) {}
    
    ngOnInit() {
        this.loadingLabels = true;
        this.showInitializeButton = false;

        this.adminService.getDocumentsLabels().subscribe(
            {
                next: (result: any) => {
                    this.labels = result['values'];

                    this.loadingLabels = false;
                    this.showInitializeButton = false;
                },
                error: () => {
                    this.loadingLabels = false;
                    this.showInitializeButton = true;
                },
                complete: () => {},
            }
        );

        this.successMessage = [
            { severity: 'success', summary: 'Success', detail: '✔️ User access to B2C app updated' },
        ];
		this.errorMessage = [
            { severity: 'error', summary: 'Error', detail: '❌ There was an error' },
        ];
    }

    updateDocumentsLabels() {
        this.loadingLabels = true;

        this.adminService.getDocumentsLabels().subscribe((result: any) => {
            this.labels = result['values'];

            this.loadingLabels = false;
            this.showInitializeButton = false;
        });
    }

    createLabel() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                if(this.newLabel.en == '' || this.newLabel.fr == '' || this.newLabel.nl == '') {
                    this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'All fields are required' });
                } else {
                    this.adminService.addDocumentLabel(this.newLabel.en, this.newLabel.fr, this.newLabel.nl).subscribe(
                        {
                            complete: () => {
                                this.updateDocumentsLabels();
                                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User removed from allowed emails list' });
                            },
                            error: () => {
                                this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                            }
                        }
                    ) 
                }
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        })
    }

    initializeDocumentsLabels() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService.initializeDocumentsLabels().subscribe(
                    {
                        complete: () => {
                            this.updateDocumentsLabels();
                            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Documents Labels initialized.' });
                        },
                        error: () => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        }
                    }
                ) 
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        })
    }

    changeStatus(label: any) {
		const dialogRef = this.dialogRef.open(DocumentLabelEnableModalComponent, { disableClose: true, data: { label: label } });

		dialogRef.afterClosed().subscribe(result => {
			if(result === 'success') {
				this.showSuccessMessage = true;
				setTimeout(() => { this.showSuccessMessage = false; }, 3000);
			} else if(result === 'back') {
				this.showErrorMessage = true;
				setTimeout(() => { this.showErrorMessage = false; }, 3000);
				label.enabled = !label.enabled;
			}

			
		})
	}
}
