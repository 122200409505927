import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SwUpdateService {
	prompt: any;

	showButtonInstall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showButtonInstall$: Observable<boolean> = this.showButtonInstall.asObservable();
	showButtonUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showButtonUpdate$: Observable<boolean> = this.showButtonUpdate.asObservable();
	installed: boolean = false;

	constructor(private swUpdate: SwUpdate) {
		this.swUpdate.versionUpdates.subscribe((event: any) => {
			switch (event.type) {
				case 'VERSION_DETECTED': 
					console.log(`Downloading new app version: ${event.version.hash}`);
					this.installed = true;
					this.update();
					break;
				case 'VERSION_READY':
					console.log(`Current app version: ${event.currentVersion.hash}`);
					console.log(`New app version ready for use: ${event.latestVersion.hash}`);
					window.location.reload();
					break;
				case 'VERSION_INSTALLATION_FAILED':
					console.log(`Failed to install app version '${event.version.hash}': ${event.error}`);
					break;
			}			
			
			// if (window.confirm('New version available. Update?')) {
			// 	this.update();
			// } else {
			// 	this.showButtonUpdate.next(true);
			// }
		});
	}

	promptEvent = (e: any) => {
		this.prompt = e;
		if (!this.installed)
			setTimeout(() => {
				this.showButtonInstall.next(true);
			}, 1000);
	};

	showPrompt = () => {
		this.prompt.prompt();
		this.prompt.userChoice.then((result: any) => {
			if (result.outcome === 'accepted') {
				this.showButtonInstall.next(false);
				this.installed = true;
			} else {
				this.showButtonInstall.next(true);
				this.installed = false;
			}
			this.prompt = null;
		});
	};

	update = () => {
		window.location.reload();
		this.showButtonUpdate.next(false);
	};

	checkUpdates(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			if(this.swUpdate.isEnabled)
				resolve(this.swUpdate.checkForUpdate());
			else
				resolve(false);
		})
	};
}
