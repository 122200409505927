<section class="login">
	<div class="log-form">
		<div id="img"><img src="../../assets/img/plenion-logo.png" alt="Plenion logo" /></div>
		<h1 id="h1" translate>We are looking forward</h1>
		<h1><span translate>to grow up your business!</span></h1>

		<div class="log-form-cont" *ngIf="!domains && !tokens">
			<div class="buttons-wrapper">
				<div class="login-button button-blue" (click)="login('Google')">
					<div class="button-wrapper">
						<div class="button-icon">
							<div class="button-icon-svg-container">
								<svg
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									width="18px"
									height="18px"
									viewBox="0 0 48 48"
									class="button-icon-svg"
								>
									<g>
										<path
											fill="#EA4335"
											d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
										></path>
										<path
											fill="#4285F4"
											d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
										></path>
										<path
											fill="#FBBC05"
											d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
										></path>
										<path
											fill="#34A853"
											d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
										></path>
										<path fill="none" d="M0 0h48v48H0z"></path>
									</g>
								</svg>
							</div>
						</div>
						<span class="button-text-container">
							<span class="button-text" translate>Sign in with Google</span>
						</span>
					</div>
				</div>
				<div class="login-button button-dark" (click)="login('Office')">
					<div class="button-wrapper">
						<div class="button-icon">
							<div class="button-icon-svg-container">
								<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 21 21" class="button-icon-svg">
									<rect x="1" y="1" width="9" height="9" fill="#f25022" />
									<rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
									<rect x="11" y="1" width="9" height="9" fill="#7fba00" />
									<rect x="11" y="11" width="9" height="9" fill="#ffb900" />
								</svg>
							</div>
						</div>
						<span class="button-text-container">
							<span class="button-text" translate>Sign in with Microsoft</span>
						</span>
					</div>
				</div>
			</div>
			<div class="input">
				<mat-checkbox [(ngModel)]="remember" class="remember-input">
					{{"Remember me" | translate}}
				</mat-checkbox>
			</div>
			<div class="dropdown-language-container">
				<div id="language-dropdown" [matMenuTriggerFor]="menuLanguage">
					<span>{{ selected }}</span>
					<mat-icon>keyboard_arrow_down</mat-icon>
				</div>
				<mat-menu #menuLanguage="matMenu">
					<a (click)="onChangeLanguage('en')" value="EN" mat-menu-item>
						<span>English</span>
					</a>
					<a (click)="onChangeLanguage('fr')" value="FR" mat-menu-item>
						<span>Français</span>
					</a>
					<a (click)="onChangeLanguage('nl')" value="NL" mat-menu-item>
						<span>Nederlands</span>
					</a>
				</mat-menu>
			</div>
		</div>

		<!-- DOMAIN SELECTION -->
		<div class="log-form-cont" *ngIf="domains && tokens">
			<div class="domain-selection">
				<mat-form-field appearance="fill">
					<mat-label translate>Choose a domain</mat-label>
					<mat-select [(value)]="domain_selected">
						<mat-option *ngFor="let domain of domains" value="{{ domain }}">{{ domain }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="domain-button-wrapper">
				<button class="button" [ngClass]="{ disabled: !domain_selected }" [disabled]="!domain_selected" (click)="continue()" translate>
					Continue
				</button>
			</div>
		</div>
		
	</div>
</section>
