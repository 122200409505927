<div *ngIf="!isLoadingTypes && !isLoadingResources">
    <p-card class="info-card">
        <div class="types">
            <div class="user-types">
                <h4 style="font-weight: bold;">{{ "Select default portal types" | translate }}</h4>
                <p-divider></p-divider>
                <div class="user-types-content">
                    <div *ngFor="let type of this.allTypes">
                        <li>
                            <input #typesCheckbox type="checkbox" [id]="type" [name]="type" value="true" [checked]="this.types.includes(type)">
                            <div class="li-spacer"></div>
                            <label [for]="type">{{ formatTypeNames(type) | translate }}</label>
                        </li>
                    </div>
                </div>
            </div>

            <p-divider layout="vertical"></p-divider>

            <div class="resources-categories">
                <h4 style="font-weight: bold;">{{ "Select default resources categories" | translate }}</h4>
                
                <p-divider></p-divider>
                <li>
                    <input #Werknemer type="checkbox" id="Werknemer" name="Werknemer" value="true" [checked]="this.resources.includes('Werknemer')">
                    <div class="li-spacer"></div>
                    <label for="Employee">{{ "Employee" | translate }}</label><br>
                </li>
                <li>
                    <input #Voertuig type="checkbox" id="Voertuig" name="Voertuig" value="true" [checked]="this.resources.includes('Voertuig')">
                    <div class="li-spacer"></div>
                    <label for="Voertuig">{{ "Vehicle" | translate }}</label><br>
                </li>
                <li>
                    <input #Machine type="checkbox" id="Machine" name="Machine" value="true" [checked]="this.resources.includes('Machine')">
                    <div class="li-spacer"></div>
                    <label for="Machine">{{ "Machine" | translate }}</label><br>
                </li>
            </div>
        </div>
    </p-card>
    <div class="confirmation-button" *ngIf="!isLoadingTypes">
        <p-toast></p-toast>
        <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
        <p-button (click)="updateValues()" icon="pi pi-check" label="Save changes"></p-button>
    </div>
</div>