import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Message, SelectItem } from 'primeng/api';
import { B2cEnableModalComponent } from 'src/app/shared/modals/b2c-enable-modal/b2c-enable-modal.component';
import { AdminService } from 'src/app/services/admin.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-contacts-view',
  templateUrl: './contacts-view.component.html',
  styleUrls: ['./contacts-view.component.scss']
})
export class ContactsViewComponent {
    @Input() loadingContact: boolean = true;
	@Input() contacts: any[] = [];
    @Input() functions: SelectItem[] = [];

	cols: any[] = [];

	successMessage: Message[] = [];
	errorMessage: Message[] = [];
	notEnabledMessage: Message[] = [];
	showSuccessMessage: boolean = false;
	showErrorMessage: boolean = false;
	plenionB2C: string = 'true';

    selectedContact: any;
	myTab: any;
	constructor(private dialogRef: MatDialog, private adminService: AdminService, private commonService: CommonService) {}

	ngOnInit(): void {
		this.plenionB2C = this.commonService.plenionB2C;

		this.adminService.getTouchUsers().subscribe((results) => {
            this.contacts = results.values;
            this.loadingContact = false;
        })

		this.cols = [
            { field: 'contact_id', header: 'ID' },
            { field: 'contact_email', header: 'Email' },
            { field: 'contact_token', header: 'Token' },
			{ field: 'customer_name', header: 'Customer Name' },
			{ field: 'customer_id', header: 'Customer ID' },
			{ field: 'domain', header: 'Domain' },
			{ field: 'lastModification', header: 'Last Modification' },
        ];

		this.successMessage = [
            { severity: 'success', summary: 'Success', detail: '✔️ User access to B2C app updated' },
        ];
		this.errorMessage = [
            { severity: 'error', summary: 'Error', detail: '❌ There was an error' },
        ];

		this.notEnabledMessage = [
			{ severity: 'error', summary: 'Error', detail: 'Plenion Touch (B2C) is not enabled for ' + this.commonService.companyName }
		];
    }

	changeStatus(contact: any) {
		const dialogRef = this.dialogRef.open(B2cEnableModalComponent, { disableClose: true, data: { contact: contact } });

		dialogRef.afterClosed().subscribe(result => {
			if(result === 'success') {
				this.showSuccessMessage = true;
				setTimeout(() => { this.showSuccessMessage = false; }, 3000);
			} else if(result === 'back') {
				this.showErrorMessage = true;
				setTimeout(() => { this.showErrorMessage = false; }, 3000);
				contact.isEnabled = !contact.isEnabled;
			}

			
		})
	}

}
