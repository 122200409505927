export class GlobalConstants {
	public static defaultLanguage: string = 'nl';

    public static resourcesCategories: any[] = [
		{label: 'Werknemer', value:'Werknemer', en: 'Employee', fr: 'Employé', nl: 'Werknemer'}, 
		{label: 'Voertuig', value:'Voertuig', en: 'Vehicle', fr: 'Véhicule', nl: 'Voertuig'}, 
		{label: 'Machine', value:'Machine', en: 'Machine', fr: 'Machine', nl: 'Machine'}
	];

	public static typeOrder: string[] = [
		'Customers', 'Suppliers', 'Prospect', 'Customeraddresses', 'Projects', 'Articles', 'Resources', 'Installations',
		'Request', 'Salesdocuments', 'Salesinvoices', 'Purchasedocuments', 'Purchaseinvoices', 'Salesopportunities', 'Invoices', 'Documents Scanned'
	]


}