import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'src/app/shared/material.module';
import { MatButtonModule } from '@angular/material/button';
import { MessagesModule } from 'primeng/messages';
import { DocumentLabelEnableModalComponent } from './documentlabel-enable-modal.component';


@NgModule({
	imports: [
		CommonModule,
		TranslateModule, 
		FormsModule, 
		ReactiveFormsModule, 
		DropdownModule, 
		SharedModule, 
		MaterialModule, 
		MatDialogModule,
		MatButtonModule,
		MessagesModule
	],
	declarations: [DocumentLabelEnableModalComponent],
	exports: [DocumentLabelEnableModalComponent, MessagesModule],
})
export class DocumentLabelEnableModule {}
