import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { AuthService } from '../auth/auth-service.service';
import { GooglePickerService } from 'src/app/shared/google-picker/google-picker.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ActionsService } from 'src/app/services/actions.service';

@Component({
  selector: 'app-login-customer',
  templateUrl: './login-customer.component.html',
  styleUrls: ['./login-customer.component.scss']
})
export class LoginCustomerComponent {
	showLogIn: boolean = false;
	showFirebaseForgot: boolean = false;
	email: FormControl = new FormControl('', [Validators.required]);
	password: FormControl = new FormControl('', [Validators.required]);
	matcher = new MyErrorStateMatcher();

	notRegistered: any;
	emailParam: string = "";
	remember: boolean = false;
	errorMessage: string = "";
	domain_selected: string = "";
	domains: string[] = [];
	tokens: string[] = [];

	selected = 'NL';
	language: string = 'nl';

	constructor(
		private translate: TranslateService,
		private authService: AuthService,
		private googlePickerService: GooglePickerService,
		private router: Router,
		private http: HttpClient,
		private actionsService: ActionsService,
	) {
		this.language = localStorage.getItem('language')!;
		this.changeLanguage(this.language);
	}

	ngOnInit() {
		this.showLogIn = false;
		if (this.authService.getToken()) {
			this.router.navigate(['users']);
		}

		var url = new URL(window.location.href);
		this.notRegistered = url.searchParams.get('notRegistered');
		this.emailParam = url.searchParams.get('email')!;

		if (url.searchParams.get('domains') && url.searchParams.get('tokens')) {
			this.domains = url.searchParams.get('domains')!.split(',');
			this.tokens = url.searchParams.get('tokens')!.split(',');
			this.domains = this.domains.filter((s) => s !== '');
			this.tokens = this.tokens.filter((s) => s !== '');
		}

		if (this.notRegistered != null) {
			if (this.emailParam) {
				this.actionsService.set({ type: 'notification', status: 'none', message: `❌ ${this.emailParam} is not registered` });
			} else {
				this.actionsService.set({ type: 'notification', status: 'none', message: `❌ You are not registered` });
			}
		}
	}

	login(type: string) {
		this.authService.authWithProvider(type);
	}

	showFirebase() {
		this.showLogIn = true;
		this.showFirebaseForgot = false;
	}

	hideFirebase() {
		this.showLogIn = false;
		this.showFirebaseForgot = false;
	}

	showForgot() {
		this.showFirebaseForgot = true;
		this.showLogIn = false;
	}

	hideForgot() {
		this.showFirebaseForgot = false;
		this.showLogIn = true;
	}

	submitFirebase() {
		this.errorMessage = '';
		this.authService
			.authenticateFirebase(this.email.value, this.password.value)
			.then((value) => {})
			.catch((value) => {
				this.actionsService.set({ type: 'notification', status: 'none', message: '❌ ' + value });
			});
	}

	submitRecover() {
		this.authService.passwordResetFirebase(this.email.value).then((response) => {
			this.showFirebaseForgot = false;
			this.showLogIn = false;
		});
	}

	continue() {
		let index = this.domains.indexOf(this.domain_selected);

		this.router.navigateByUrl(`/customerLogin?token=${this.tokens[index]}`);
		window.location.href = `/customerLogin?token=${this.tokens[index]}`;
	}

	changeLanguage(language: string) {
		switch (language) {
			case 'en':
				this.selected = 'EN';
				this.language = 'en';
				break;
			case 'fr':
				this.selected = 'FR';
				this.language = 'fr';
				break;
			case 'nl':
				this.selected = 'NL';
				this.language = 'nl';
				break;
			default:
				this.selected = 'NL';
				this.language = 'nl';
		}

		this.translate.use(this.language);
	}

	onChangeLanguage(language: string) {
		this.translate.use(language);
		localStorage.setItem('language', language);
		this.changeLanguage(language);
		this.googlePickerService.getLanguageForPicker(this.language);
	}
}
