<div *ngIf="!loadingUser">
    <h5 class="description"><b>{{ "User Info" | translate }}</b></h5>
    <div class="user-info-cards">
        <p-card class="info-card-calendar">
            <div class="user-info-main-card">
                <div class="user-info-main-card-text">
                    <div class="user-info">
                        <h5 style="font-weight: bold;">{{ "User ID" | translate }}</h5>
                        <span>{{ user.idUser }}</span>
                    </div>
                    <div class="user-info">
                        <h5 style="font-weight: bold;">{{ "Email" | translate }}</h5>
                        <input pInputText type="text" placeholder="email" [(ngModel)]="user.email" />
                    </div>
                    <div class="user-info-calendar">
                        <h5 style="font-weight: bold;">{{ "Calendar" | translate }}</h5>
                        <div class="calendar-span" *ngIf="user.calendar">{{ user.calendar }}</div>
                        <div class="calendar-span" *ngIf="!user.calendar">{{ "Calendar not assigned" | translate }}</div>
                    </div> 
                </div>
                <div class="user-info-main-card-input-switch">
                    <div class="user-info">
                        <h5 style="font-weight: bold;">API</h5>
                        <p-inputSwitch [(ngModel)]="user.apiEnabled"></p-inputSwitch>
                    </div>
                    <div class="user-info">
                        <h5 style="font-weight: bold;">PORTAL</h5>
                        <p-inputSwitch [(ngModel)]="user.portalEnabled"></p-inputSwitch>
                    </div>
                    <div class="user-info">
                        <h5 style="font-weight: bold;">ADMIN</h5>
                        <p-inputSwitch [(ngModel)]="user.admin"></p-inputSwitch>
                    </div>
                </div>
            </div>
        </p-card>
        <p-card class="info-card">
            <div class="types">
                <div class="user-types">
                    <h5 style="font-weight: bold;">{{ "Select portal types for user" | translate }}</h5>
                    <div class="default">
                        <h6 style="font-weight: bold;"><u><strong>{{ "Default types" | translate }}</strong></u></h6>
                        <p-inputSwitch class="default-switch" [(ngModel)]="user.useDefaultTypes"></p-inputSwitch>
                    </div>
                    
                    <ul *ngIf="!user.useDefaultTypes">
                        <li *ngFor="let type of this.allTypes">
                            <input #typesCheckbox type="checkbox" [id]="type" [name]="type" value="true" [checked]="this.types.includes(type)">
                            <label [for]="type">{{ formatTypeNames(type) | translate }}</label><br>
                        </li>
                    </ul>
                </div>
                <div class="resources-categories">
                    <h5 style="font-weight: bold;">{{ "Select resources categories for user" | translate }}</h5>
                    <div class="default">
                        <h6 style="font-weight: bold;"><u><b>{{ "Default resources" | translate }}</b></u></h6>
                        <p-inputSwitch class="default-switch" [(ngModel)]="user.useDefaultResources"></p-inputSwitch>
                    </div>
                    <ul *ngIf="!user.useDefaultResources">
                        <li>
                            <input #Werknemer type="checkbox" id="Werknemer" name="Werknemer" value="true" [checked]="this.resources.includes('Werknemer')">
                            <label for="Employee">{{ "Employee" | translate }}</label><br>
                        </li>
                        <li>
                            <input #Voertuig type="checkbox" id="Voertuig" name="Voertuig" value="true" [checked]="this.resources.includes('Voertuig')">
                            <label for="Voertuig">{{ "Vehicle" | translate }}</label><br>
                        </li>
                        <li>
                            <input #Machine type="checkbox" id="Machine" name="Machine" value="true" [checked]="this.resources.includes('Machine')">
                            <label for="Machine">{{ "Machine" | translate }}</label><br>
                        </li>
                    </ul>
                </div>
            </div>
        </p-card>
    </div>
    <div class="confirmation-button">
        <p-toast></p-toast>
        <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
        <p-button (click)="editUser()" icon="pi pi-check" label="Save user"></p-button>
    </div>
</div>