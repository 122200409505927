import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { GlobalConstants } from './utils/global-constants';
import { CommonService } from './services/common.service';

@Injectable({
	providedIn: 'root',
})
export class AppService {
    types: any;
	types_array: string[] = [];

	private apiUrl: String = environment.apiUrl;
	private defaultTypes: string[] = ['Customers', 'Projects', 'Articles', 'Resources', 'Suppliers', 'Installations'];

	constructor(private http: HttpClient, private commonService: CommonService) {}

	checkPlenionB2C() {
		const url = `${this.apiUrl}portal/v0.1/user/plenionTouchAccess`;
		return this.http
			.get(url)
			.toPromise()
			.then((response: any) => {				
				this.commonService.plenionB2C = response.access.toString();
			})
			.catch((err) => {
				this.commonService.plenionB2C = 'false';
			});
	}
}
