<div class="panel-content-table" >
    <p-table 
        #dt
        [value]="logs" 
        [tableStyle]="{ 'min-width': '50rem' }"
        [globalFilterFields]="['channel', 'event', 'ip', 'timeStamp', 'users', 'filename', 'idFile', 'receiverUserEmail', 'senderUserId']"
        [paginator]="true"
        [rows]="20"
        [sortField]="defaultSortField" 
        [sortOrder]="-1"
        >
        <ng-template pTemplate="caption"> 
            <div class="flex">
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>{{"Channel" | translate}}</th>
                <th>{{"Event" | translate}}</th>
                <!-- <th>{{"Expiration Date" | translate}}</th> -->
                <th>{{"IP" | translate}}</th>
                <th>{{"TimeStamp" | translate}}</th>
                <th>{{"Users" | translate}}</th>
                <th>{{"Filename" | translate}}</th>
                <th>{{"File ID" | translate}}</th>
                <th>{{"Receiver Email" | translate}}</th>
                <th>{{"Sender User" | translate}}</th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <!-- <th></th> -->
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-logDownload>
            <tr>
                <td>{{ logDownload.channel }}</td>
                <td>{{ logDownload.event }}</td>
                <!-- <td>{{ logDownload.expirationDate }}</td> -->
                <td>{{ logDownload.ip }}</td>
                <td>{{ logDownload.timeStamp }}</td>
                <td>{{ logDownload.users }}</td>
                <td>{{ logDownload.filename }}</td>
                <td>{{ logDownload.idFile }}</td>
                <td>{{ logDownload.receiverUserEmail }}</td>
                <td>{{ logDownload.senderUserId }}</td>
            </tr>
        </ng-template>
        <tr *ngIf="!loadingLogs" colspan="3" style="padding: 0">
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </tr>
        <!-- <ng-template pTemplate="summary"></ng-template> -->
    </p-table>
</div>