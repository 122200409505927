<div *ngIf="!loadingTouchConfig" class="card-display">
    <p-card>
        <h2><b style="color: #e88900 !important;">{{ "Plenion Touch configuration" | translate}}</b></h2>
        <ul class="info-list">
            <div class="option-list">
                <div>
                    <li>
                        <div class="company-logo" style="display: flex;">
                            <h5><b>{{ "Company Logo" | translate }}</b></h5>
                            <mat-icon (click)="removeLogo()" class="button-icon">{{"delete" | translate}}</mat-icon>
                        </div>
                        <label for="fileInput" class="image-label">
                            <img [src]="touchConfiguration.logo" *ngIf="touchConfiguration.logo != 'data:image/png;base64,undefined'" [style.width.px]="200"/>
                            <img src="assets/img/add_image.png" *ngIf="touchConfiguration.logo == 'data:image/png;base64,undefined'" [style.width.px]="200"/>
                            <input type="file" id="fileInput" style="display: none;" (change)="onFileSelected($event)" />
                        </label>
                    </li>
                </div>
                <div>
                    <li>
                        <h5><b>{{"Company Name" | translate }}</b></h5>
                        <input pInputText type="text" placeholder="Company name" [(ngModel)]="touchConfiguration.companyName" />
                    </li>
                    <li>
                        <h5><b>{{"Fiscal Info" | translate }}</b></h5>
                        <input pInputText type="text" placeholder="Fiscal info" [(ngModel)]="touchConfiguration.fiscalInfo" />
                    </li>
                    <li>
                        <h5><b>{{ "Phone Number" | translate }}</b></h5>
                        <input pInputText type="text" placeholder="Phone number" [(ngModel)]="touchConfiguration.phoneNumber" />
                    </li>
                    <li>
                        <h5><b>{{ "Address" | translate }}</b></h5>
                        <input pInputText type="text" placeholder="Address" [(ngModel)]="touchConfiguration.address" />
                    </li>
                </div>
                <div>
                    <li>
                        <h5><b>{{ "Email" | translate }}</b></h5>
                        <input pInputText type="text" placeholder="Email" [(ngModel)]="touchConfiguration.email" />
                    </li>
                    <li>
                        <h5><b>{{ "Website URL" | translate }}</b></h5>
                        <input pInputText type="text" placeholder="Website URL" [(ngModel)]="touchConfiguration.websiteURL" />
                    </li>
                    <li>
                        <h5><b>{{ "Website Message" | translate }}</b></h5>
                        <textarea class="pInputTextCustom" [(ngModel)]="touchConfiguration.welcomeMsg"></textarea>
                    </li>
                </div>
            </div>
        </ul>

        <div class="confirmation-button">
            <p-toast></p-toast>
            <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
            <p-button (click)="saveConfiguration()" icon="pi pi-check" label="Save changes"></p-button>
        </div>
    </p-card>
</div>
