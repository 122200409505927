import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth-service.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(public auth: AuthService, private cookieService: CookieService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: {
				Authorization: `Bearer ${this.auth.getToken()}`,
			},
		});

		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				return event;
			}),
			catchError((error: HttpErrorResponse) => {
				return this.handleResponseError(error, request, next);
			}),
		);
	}

	handleResponseError(error: any, request?: any, next?: any) {
		if (error.status === 400) console.error('[400] Error in request');
		else if (error.status === 401) {
			this.auth.logout();
			this.cookieService.deleteAll();
			this.auth.kickSession();
		} 
		else if (error.status === 403) this.auth.logout();
		else if (error.status === 500) console.error('[500] Server error');
		else if (error.status === 503) console.error('[503] Server error');

		return throwError(error);
	}
}
