import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/auth/auth-service.service';
import { StatusService } from './services/status.service';
import { GlobalConstants } from './utils/global-constants';
import { MessagingService } from './services/messaging.service';
import { SwUpdateService } from './services/sw-update.service';
import { AppService } from './app.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from './services/common.service';
import { Router } from '@angular/router';
import { AdminService } from './services/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'Plenion Portal';
	loading: boolean = false;
	loadingViewPage: boolean = false;
	loggedIn: boolean = false;
	message: string = 'Loading...';
	types: string[] = [];
	online: boolean = false;
	collided: boolean = false;
	isSupport: boolean = false;

	error: boolean = false;

	language: string = 'en';
	private token: any;

	@HostListener('window:beforeinstallprompt', ['$event'])
	onbeforeinstallprompt(e: any) {
		e.preventDefault();
		this.swUpdateService.promptEvent(e);
	}

	constructor(
		public translate: TranslateService,
		private authService: AuthService,
		private messagingService: MessagingService,
		private swUpdateService: SwUpdateService,
		private statusService: StatusService,
		private appService: AppService,
		private cookieService: CookieService,
		private commonService: CommonService,
		private adminService: AdminService,
		private router: Router,
	) {
		this.language = localStorage.getItem('language')!;
		if (this.language) {			
			translate.setDefaultLang(this.language);
			translate.use(this.language);
			localStorage.setItem('language', this.language);
		} else {
			translate.setDefaultLang(GlobalConstants.defaultLanguage);
			translate.use(GlobalConstants.defaultLanguage);
			localStorage.setItem('language', GlobalConstants.defaultLanguage);
		}
	}

	async ngOnInit() {
		this.statusService.connection$.subscribe(async (value) => {
			this.online = value;
			// if (value && this.authService.getToken()) await this.appService.getActivedTypes().toPromise();
		});

		this.swUpdateService.checkUpdates()
			.then(() => {})
			.catch(() => {
				console.error('Service worker: error checking updates');
			});

		this.authService.loggedIn$.subscribe((value) => (this.loggedIn = value));

		this.loading = true;
		this.message = 'Checking session...';
		const token = await this.authService.isLoggedIn();

		const data = this.cookieService.get('LOG');
		var url = new URL(window.location.href);
		this.isSupport = url.searchParams.get('site') === 'support';
		let supportToken = url.searchParams.get('token');

		if(this.isSupport && supportToken){
			this.commonService.token = supportToken!;
			this.commonService.isSupport = 'true';

			this.message = 'Receiving data...';
			// We set the cookies to know if the user has to login again
			this.loggedIn = true;
			this.loadingViewPage = false;
			this.loading = false;
			

			this.router.navigate(['support']);
		}

		if (token) {
			this.token = token;
			this.authService.createUser();
			this.loggedIn = true;
			
			if (this.online) {
				this.message = 'Receiving data...';
				this.loading = false;
				await this.appService.checkPlenionB2C();
			} else {
				this.loading = false;
			}
		} else {
			this.message = 'Session not found.';
			this.token = url.searchParams.get('token');

			if (this.token)  {
				this.commonService.token = this.token;
				this.message = 'Logging in...';
				this.loadingViewPage = true;

				if ( !this.isSupport ) {
					
					this.authService.authenticate(this.token).subscribe(
						{
							complete: () => {
								this.message = 'Receiving data...';
								// We set the cookies to know if the user has to login again
								this.loggedIn = true;
								this.loadingViewPage = false;
								this.loading = false;
								this.appService.checkPlenionB2C();
								
							},
							error: () => {
								this.loggedIn = false;
							}
						}
					);
				}else{
					this.message = 'Receiving data...';
					// We set the cookies to know if the user has to login again
					this.loggedIn = true;
					this.loadingViewPage = false;
					this.loading = false;

					this.router.navigate(['support']);
				}

			} else {
				if (!url.searchParams.get('tokens') && !url.pathname.includes("login")) this.authService.kickSession();

				this.loading = false;
				this.loggedIn = false;
			}
		}
	}
}
