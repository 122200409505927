import { AfterViewInit, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { isSuccess } from 'angular-in-memory-web-api';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { User } from 'src/app/models/auth/user.model';
import { PortalUser } from 'src/app/models/user/user.model';
import { AdminService } from 'src/app/services/admin.service';
import { MainContentService } from 'src/app/services/main-content.service';
import { StatusService } from 'src/app/services/status.service';
import { formatTypeNames } from 'src/app/utils/functions';
import { GlobalConstants } from 'src/app/utils/global-constants';  

@Component({
    selector: 'app-edit-user-view',
    templateUrl: './edit-user-view.component.html',
    styleUrls: ['./edit-user-view.component.scss'],
})
export class EditUserViewComponent {
    @ViewChild('Werknemer') werknemer!: any;
    @ViewChild('Voertuig') voertuig!: any;
    @ViewChild('Machine') machine!: any;

    @ViewChildren('typesCheckbox') typesCheckbox!: QueryList<any>;
	unsubscriber$: Subject<any> = new Subject();

    loadingUser: boolean = true;
    idUser: string = "";
    user!: PortalUser;

    allTypes: string[] = [];
    types: string[] = [];
    resources: string[] = [];


    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private adminService: AdminService,
        private mainContentService: MainContentService
    ) {}

    ngOnInit(): void {
        this.loadingUser = true;
        this.activeRoute.paramMap.pipe(takeUntil(this.unsubscriber$)).subscribe((params: ParamMap) => {
            this.idUser = params.get("idUser")!;
            this.allTypes = GlobalConstants.typeOrder;

            this.adminService.getDomainUser(this.idUser).subscribe((result: any) => {
                this.user = result['user'];
                if(this.user.userTypes) this.types = this.user.userTypes!;
                else this.types = [];

                if(this.user.resourcesCategories) this.resources = this.user.resourcesCategories!;
                else this.resources = [];

                this.loadingUser = false;
            }) 
        });
    }

    editUser(): void {
        let newTypesArray: string[] = [];
        let newResourcesArray: string[] = [];

        if(!this.user.useDefaultTypes) {
            // We get the types states from the list
            this.typesCheckbox!.forEach((type) => {
                if(type.nativeElement.checked) {
                    newTypesArray.push(type.nativeElement.name);
                }
            });

            this.user.useDefaultTypes = false
        }

        if(!this.user.useDefaultResources) {
            // We get the resources states from the list
            if(this.werknemer.nativeElement.checked)
                newResourcesArray.push("Werknemer")
    
            if(this.voertuig.nativeElement.checked)
                newResourcesArray.push("Voertuig")
    
            if(this.machine.nativeElement.checked)
                newResourcesArray.push("Machine")

            this.user.useDefaultResources = false;
        }

        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                let admin = false;
                if(this.user.admin) 
                    admin = this.user.admin;

                this.adminService.setDomainUserAccess(this.user.idUser, this.user.email, this.user.apiEnabled, this.user.portalEnabled, admin, this.user.useDefaultTypes!, this.user.useDefaultResources!).subscribe(
                    {
                        complete: () => {
                            this.adminService.setUserTypes(this.user.idUser, newTypesArray).subscribe(
                                {
                                    complete: () => {
                                        this.adminService.setUserResources(this.user.idUser, newResourcesArray).subscribe(
                                            {
                                                complete: () => {
                                                    this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User updated' });
                                                },
                                                error: () => {
                                                    this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                                                }
                                            }
                                        );
                                    },
                                    error: () => {
                                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                                    }
                                }
                                
                            )
                            // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User updated' });
                        },
                        error: () => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        }
                    }
                );
                
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        });
    }

    updateUser() {
        this.loadingUser = true;
        this.adminService.getDomainUser(this.idUser).subscribe((result: any) => {
            this.user = result['user'];
            this.types = this.user.userTypes!;
            this.resources = this.user.resourcesCategories!;

            this.loadingUser = false;
        }) 
    }

    formatTypeNames(type: string) {
        return formatTypeNames(type);
    }
}
