<div class="enable-b2c-modal">
    <h1 mat-dialog-title><b>Modify access of a Document Label</b></h1>
    <mat-dialog-content class="mat-typography">
        <p>{{"You are going to modify the access to the Document Label" | translate }} <b>-{{data.label.en}}-</b> <br>
        <b>{{"Are you sure you want to change the access?" | translate}}</b>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button (click)="onBack()" mat-button mat-dialog-close>Go Back</button>
        <button *ngIf="!data.label.enabled" (click)="onSubmit()" mat-button>Disable access</button>
        <button *ngIf="data.label.enabled" (click)="onSubmit()" mat-button>Enable access</button>
        </mat-dialog-actions>
</div>