import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-table-tool-bar',
  templateUrl: './button-table-tool-bar.component.html',
  styleUrls: ['./button-table-tool-bar.component.scss']
})
export class ButtonTableToolBarComponent {
    @Input() icon: string = "";
	@Input() label: string = "";
	@Input() disabled: boolean = false;

	constructor() {}

	ngOnInit(): void {}
}
