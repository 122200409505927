import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    token: string = "";
    plenionB2C: string = "";
    companyName: string = "";
    isAdmin: string = "";
    isSupport: string = "";
    isInvoiceEnabled: string = "";
    isRoutesEnabled: string = "";
}