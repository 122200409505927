<div class="panel-content-table" >
    <p-table 
    #dt
    [value]="domainList" 
    [paginator]="true"
    [rows]="10" 
    selectionMode="single"
    [tableStyle]="{'min-width': '50rem'}"
    [globalFilterFields]="['domainId', 'type']"
    [(selection)]="selectedDomain"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    >

    <ng-template pTemplate="caption"> 
        <div class="flex">
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search keyword" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr *ngIf="loadingDomains">
            <td [attr.colspan]="9">
                <mat-progress-bar class="content-progress-bar" color="primary" mode="indeterminate"> </mat-progress-bar>
            </td>
        </tr>
        <tr>
            <th>{{"Domain ID" | translate}}</th>
            <th>{{"Portal" | translate}}</th>
            <!-- <th>{{"Expiration Date" | translate}}</th> -->
            <th>{{"Api" | translate}}</th>
            <th>{{"Invoice" | translate}}</th>
            <th>{{"Invoice Credits" | translate}}</th>
            <th>{{"User Permissions" | translate}}</th>
            <th>{{"Plenion Touch (B2C)" | translate}}</th>
            <th>{{"Plenion Web Services" | translate}}</th>
            <th>{{"Type" | translate}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-domain>
        <tr [pSelectableRow]="domain">
            <td>{{ domain.domainId}}</td>
            <td>{{ domain.portal }}</td>
            <td>{{ domain.api }}</td>
            <td>{{ domain.invoices }}</td>
            <td>{{ domain.invoiceCredits }}</td>
            <td>{{ domain.userPermissions }}</td>
            <td>{{ domain.plenionB2C }}</td>
            <td>{{ domain.plenionWebServices }}</td>
            <td>{{ domain.type }}</td>
        </tr>
    </ng-template>
    </p-table>
</div>