<div class="panel-content-table" >
    <p-table 
        #dt
        [filterDelay]="0" 
        [paginator]="true" 
        editMode="row" 
        [rows]="10" 
        [value]="users"
        dataKey="UserID"
        (onRowSelect)="onRowSelect($event)"
		(onRowUnselect)="onRowUnselect($event)"
        [(selection)]="selectedUser"
        [tableStyle]="{ 'min-width': '50rem' }"
        [globalFilterFields]="['UserID', 'Email', 'Types', 'Resources']"
    >
        <ng-template pTemplate="caption">
            <div class="flex">
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search keyword" />
                </span>
                <app-table-tool-bar [buttons]="toolBarButtons" [buttonsMenu]="toolBarButtonsMenu" [mobile]="false"></app-table-tool-bar>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr *ngIf="loadingUsers">
                <td [attr.colspan]="9">
                    <mat-progress-bar class="content-progress-bar" color="primary" mode="indeterminate"> </mat-progress-bar>
                </td>
            </tr>
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>{{"User ID" | translate}}</th>
                <th>{{"Email" | translate}}</th>
                <th>{{"Types" | translate}}</th>
                <th>{{"Resources" | translate}}</th>
                <th>{{"Generic User" | translate}}</th>
                <th>{{"Portal" | translate}}</th>
                <th>API</th>
                <th>Admin</th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                    <p-columnFilter type="boolean" field="GenericUser"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="boolean" field="Portal"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="boolean" field="API"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="boolean" field="Admin"></p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td>
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td>
                <td>{{ user.UserID }}</td>
                <td>{{ user.Email }}</td>
                <td>{{ getArrayFormatted(user.Types) | translate }}</td>
                <td>{{ getArrayFormatted(user.Resources) |translate }}</td>
                <td style="text-align: center">{{ user.GenericUser ? '✔' : '❌' }}</td>
                <td style="text-align: center">{{ user.Portal ? '✔' : '❌' }}</td>
                <td style="text-align: center">{{ user.API ? '✔' : '❌' }}</td>
                <td style="text-align: center">{{ user.Admin ? '✔' : '❌' }}</td>
            </tr>
        </ng-template>
        <tr *ngIf="!loadingUsers" colspan="3" style="padding: 0">
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </tr>
    </p-table>
    <div class="confirmation-button">
        <p-toast></p-toast>
        <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
    </div>
</div>