export function capitalize(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function mailTo(email: string) {
	window.open(`mailto:${email}`);
}

export function mapTo(location: string, mobile: boolean) {
	if (mobile) window.open(`geo:?q=${location}`);
	else window.open(`https://www.google.es/maps/place/${location}`);
}

export function phoneTo(phone: string) {
	window.open(`tel://${phone}`);
}

export function formatTypeNames(type: string) {

	if (type.includes("Purchase") || type.includes("Sale")) {

		let str = type;
		let words = str.match(/^(Sales|Purchase)([a-z]+)/);

        if(words != null)
		    return words[1] + " " + words[2].charAt(0).toUpperCase() + words[2].slice(1);

	} else if (type.includes("Customera")) {
		let str = type;
		let words = str.match(/^(Customer)([a-z]+)/);

        if(words != null)
		    return words[1] + " " + words[2].charAt(0).toUpperCase() + words[2].slice(1);
	}

	return type;
}

export function formatDate(dateString: string) {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}