<div *ngIf="!loadingRoutesInfo" class="card-display">
    <p-card>
        <h2><b style="color: #e88900 !important;">{{ "Routes Info" | translate}}</b></h2>
        <ul class="info-list">
            <li>
                <div class="customer-info-li">
                    <h5><b>{{ "Credits" | translate }} : </b></h5>
                    <span>{{ this.customerInfo.credit }}</span>
                </div>
            </li>
            <li>
                <div class="customer-info-li">
                    <h5><b>{{ "Last Credit Charge" | translate }} : </b></h5>
                    <span>{{ formatDate(this.customerInfo.lastCreditChange) }}</span>
                </div>
            </li>
            <hr>
            <div class="p-inputgroup" *ngIf="this.isRoutesEnabled === true">
                <span class="p-inputgroup-addon">
                    {{ "Add Credits" | translate }}
                </span>
                <input type="number" min="1" pInputText placeholder="Credits" [(ngModel)]="creditsToAdd"/>
                <button class="add-credits-button" (click)="addCredits(100)"  style="background-color: #a6a6a6; border: #a6a6a6;" type="button" pButton id="add-100-credit">+100</button>
                <button class="add-credits-button" (click)="addCredits(1000)" style="background-color: #a6a6a6; border: #a6a6a6;" type="button" pButton id="add-1000-credit">+1000</button>
                <button class="add-credits-button" (click)="addCredits()" type="button" pButton icon="pi pi-plus-circle"></button>

                <p-toast></p-toast>
                <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
            </div>
        </ul>
    </p-card>
    
</div>