import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { AdminService } from 'src/app/services/admin.service';
import { MainContentService } from 'src/app/services/main-content.service';

@Component({
    selector: 'app-create-user-view',
    templateUrl: './create-user-view.component.html',
    styleUrls: ['./create-user-view.component.scss'],
})
export class CreateUserViewComponent {
    api!: boolean;
    portal!: boolean;
    admin!: boolean;

    constructor(
        private router: Router,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private adminService: AdminService,
        private mainContentService: MainContentService
    ) {
        setTimeout(() => {this.api = false; this.portal = false; this.admin = false;}, 0);
    }

    addNewUser(event: Event) {
        event.preventDefault();

        var myFields = document.getElementById("fields-form") as HTMLFormElement;
        let userID: any = null;
        let email: any = null;
        let passwd: any = null;

        if((myFields.elements.namedItem("userid") as HTMLInputElement).value)
            userID = (myFields.elements.namedItem("userid") as HTMLInputElement).value;

        if((myFields.elements.namedItem("email") as HTMLInputElement).value)
            email = (myFields.elements.namedItem("email") as HTMLInputElement).value;
           
        if((myFields.elements.namedItem("password") as HTMLInputElement).value)
            passwd = (myFields.elements.namedItem("password") as HTMLInputElement).value;

        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService.addUser(userID, email, passwd, this.api, this.portal, this.admin).subscribe(
                    {
                        complete: () => {
                            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User created succesfully' });

                            setTimeout( () => this.router.navigate([`users`]), 1500);
                        },
                        error: () => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        }
                    }
                )
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        });
    }

}
