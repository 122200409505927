import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { TouchConfig } from 'src/app/models/touch/touch.model';
import { AdminService } from 'src/app/services/admin.service';
import { MainContentService } from 'src/app/services/main-content.service';

@Component({
    selector: 'app-touch-view',
    templateUrl: './touch-view.component.html',
    styleUrls: ['./touch-view.component.scss'],
})
export class TouchViewComponent {
    unsubscriber$: Subject<any> = new Subject();

    loadingTouchConfig: boolean = true;
    touchConfiguration!: TouchConfig;
    selectedFile!: File;
    imageData!: string;

    constructor(
        private activeRoute: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private adminService: AdminService,
        private mainContentService: MainContentService
    ) {}

    ngOnInit(): void {
        this.loadingTouchConfig = true;
        this.activeRoute.paramMap
            .pipe(takeUntil(this.unsubscriber$))
            .subscribe((params: ParamMap) => {
                this.adminService
                    .getTouchConfiguration()
                    .subscribe((result: any) => {
                        this.touchConfiguration = result['values'];
                        let logo: string =
                            this.touchConfiguration['logo'].value;

                        if (logo !== '')
                            this.touchConfiguration.logo = `data:image/png;base64,${logo}`;
                            
                        this.loadingTouchConfig = false;
                    });
            });
    }

    onFileSelected(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        if (inputElement?.files?.length) {
            this.selectedFile = inputElement.files[0];

            const fileReader = new FileReader();
            fileReader.onload = (event: ProgressEvent<FileReader>) => {
                let result = event.target?.result as string;
                this.imageData = this.extractBase64Data(result);

                this.touchConfiguration.logo = `data:image/png;base64,${this.imageData}`;
            };
            fileReader.readAsDataURL(this.selectedFile);
        }
    }

    extractBase64Data(dataUrl: string): string {
        const commaIndex = dataUrl.indexOf(',') + 1;

        return dataUrl.substring(commaIndex);
    }

    saveConfiguration() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService.updateTouchConfiguration(this.touchConfiguration, this.extractBase64Data(this.touchConfiguration.logo)).subscribe(
                    {
                        complete: () => {
                            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Plenion Touch configuration updated' });
                        },
                        error: () => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        }
                    }
                ) 
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        })

        this.adminService.updateTouchConfiguration(this.touchConfiguration, this.extractBase64Data(this.touchConfiguration.logo))
    }

    removeLogo() {
        this.touchConfiguration.logo = 'data:image/png;base64,undefined';
    }
}
