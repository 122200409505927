<div class="access-message">
    <p-messages *ngIf="plenionB2C === 'false'" [(value)]="notEnabledMessage" [showTransitionOptions]="'500ms'" [enableService]="true" [closable]="false"></p-messages>
</div>


<div class="panel-messages">
    <p-messages *ngIf="showSuccessMessage" [(value)]="successMessage" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'" [enableService]="true" [closable]="true"></p-messages>
    <p-messages *ngIf="showErrorMessage" [(value)]="errorMessage" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'" [enableService]="true" [closable]="true"></p-messages>
</div>

<div *ngIf="!loadingContact" class="panel-content-table">
    <p-table #dt 
        [filterDelay]="0" 
        [paginator]="true" 
        editMode="row" 
        [rows]="10" 
        [value]="contacts" 
        dataKey="contact_id" 
        responsiveLayout="stack"
        [(selection)]="selectedContact"
        [globalFilterFields]="['contact_id', 'contact_email', 'contact_token', 'domain', 'customer_name', 'customer_id', 'lastModification']"
    >
        <ng-template pTemplate="caption">
            <div class="flex">
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th translate>ID</th>
                <th translate>{{"Email" | translate}}</th>
                <th translate>Token</th>
                <th translate>{{"Customer Name" | translate}}</th>
                <th translate>{{"Customer ID" | translate}}</th>
                <th translate>{{"Domain" | translate}}</th>
                <th translate>{{"Last Modification" | translate}}</th>
                <th style="width: 5.5rem">{{"PlenionTouch access" | translate}}</th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                    <p-columnFilter type="boolean" field="isEnabled"></p-columnFilter>
                </th>
            </tr>
            <!-- <th *ngFor="let col of cols">
                <input pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, col.filterMatchMode)">
            </th> -->
        </ng-template>
        <ng-template pTemplate="body" let-contact let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="contact">
                <td>
                    <p-tableCheckbox [value]="contact"></p-tableCheckbox>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input placeholder="{{ 'ID' | translate }}" style="width: 100%" pInputText type="text" [(ngModel)]="contact.contact_id" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ contact.contact_id }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input placeholder="{{ 'Email' | translate }}" style="width: 100%" pInputText type="text" [(ngModel)]="contact.contact_email" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ contact.contact_email }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input placeholder="{{ 'Token' | translate }}" style="width: 100%" pInputText type="text" [(ngModel)]="contact.contact_token" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ contact.contact_token }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td class="table-td hoverable">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input placeholder="{{ 'Customer Name' | translate }}" style="width: 100%" pInputText type="text" [(ngModel)]="contact.customer_name" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ contact.customer_name }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input placeholder="{{ 'Customer ID' | translate }}" style="width: 100%" pInputText type="text" [(ngModel)]="contact.customer_id" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ contact.customer_id }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input placeholder="{{ 'Domain' | translate }}" style="width: 100%" pInputText type="text" [(ngModel)]="contact.domain" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ contact.domain }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input placeholder="{{ 'Last Modification' | translate }}" style="width: 100%" pInputText type="text" [(ngModel)]="contact.lastModification" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ contact.lastModification }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="text-align: center" #status>
                    <p-inputSwitch (onChange)="changeStatus(contact)"  [(ngModel)]="contact.isEnabled"></p-inputSwitch>
                </td>
            </tr>
        </ng-template>
        <tr *ngIf="!loadingContact" colspan="3" style="padding: 0">
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </tr>
    </p-table>
</div>