import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ItemResponse } from '../models/item/item.model';
import { tap } from 'rxjs/operators';
import { capitalize } from 'src/app/utils/functions';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../core/auth/auth-service.service';

@Injectable({
	providedIn: 'root',
})
export class MainContentService {
	private apiUrl: String = environment.apiUrl;
	private apiPlenion: String = environment.apiPlenion;
	private apiKey: String = environment.apiPlenionKey;

	private dataSource = new BehaviorSubject<ItemResponse>({});
	searchAddResults = this.dataSource.asObservable();
	private token: string;

	itemSelected$ = new BehaviorSubject<any>(undefined);
	private lang: string = "";

	constructor(private http: HttpClient, private translate: TranslateService, private authService: AuthService) {
		this.token = this.authService.getToken();
	}

	setItemSelected(item: any) {
		this.itemSelected$.next(item);
	}

	loadItemSelected(id: string, type: string) {
		return this.getItems(id, capitalize(type), null, null, null, null)
			.toPromise()
			.then((response: any) => {
				if (response['Error']) throw Error('Not found');
				this.itemSelected$.next(response[id]);
			});
	}

	getItems(id: string, type: string, page: number|null, inactives: boolean|null, category: String|null, details: string|null): Observable<ItemResponse> {
		let text = '';

		if(id && !details) 
			if(inactives) 
				text = `${id} AND inactive:true OR NOT inactive`;
			else
				text = id;
		else if(!id)
			if(inactives) 
				text = 'inactive:true OR NOT inactive';
			else
				text = '';
		
		if(details)
			text = details;
		
		if(type === 'Resources')			
			if(category !== null)
				text = 'category:' + category;
			else 
				text = '';
		else {
			if(category !== null)
				text = 'bsrtcd:' + category;
			else if(id)
				text = id;
			
		}

		const url = `${this.apiUrl}portal/v0.1/search?type=${type}&text=${text}&skip=${page}&limit=15`;

		return this.http.get<ItemResponse>(url);
	}

	search(text: string, type: string): Observable<ItemResponse> {
		const url = `${this.apiUrl}portal/v0.1/search?type=${type}&text=${text ? text : ''}`;

		return this.http.get(url).pipe(tap((response: ItemResponse) => this.dataSource.next(response)));
	}

	getCustomerParksFields(id: string) {
		this.lang = this.translate.currentLang;
		if (id.includes('.')) id = id.split('.')[1];
		const params = `?auth=${this.token}&lang=${this.lang ? this.lang : 'nl'}&key=${this.apiKey}`;
		const url = `${this.apiPlenion}/customerparks/${id}${params}`;

		return this.http.get<any>(url).pipe(tap((response) => response));
	}

	checkResourcesCategories() {
		const url = `${this.apiUrl}portal/v0.1/drive/resources/categories`;
		let activedCategories: any;

		return this.http.get(url).toPromise().then((response: any) => {			
			let categories = response['value'];
			let defaultCategoriesLabels = ['Werknemer', 'Voertuig', 'Machine'];

			for(let i=0; i<categories.length; i++) {
				let index = defaultCategoriesLabels.indexOf(categories[i]);

				if(index !== -1)
					activedCategories.push(categories[i]);
			}
			
			return activedCategories;
		});

	}

	bsrtCategories(type: string, lang: String) {
		const url =  `${this.apiPlenion}/search/bsrtcd?type=` + capitalize(type) + '&lang=' + lang + '&key=' + this.apiKey + '&auth=' + this.authService.getToken() ;

		return this.http.get<JSON>(url);
	}
}
