import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../../models/auth/user.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActionsService } from '../../services/actions.service';
import { StatusService } from '../../services/status.service';
import { CheckTokenResponse, AuthResponse } from '../../models/auth/auth.model';
import * as auth from 'firebase/auth'
import { CommonService } from 'src/app/services/common.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private apiUrl: string = environment.apiUrl;

	loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	loggedIn$: Observable<boolean> = this.loggedIn.asObservable();
	user: User | undefined;

	constructor(
		private http: HttpClient,
		private router: Router,
		private afAuth: AngularFireAuth,
		private actionsService: ActionsService,
		private statusService: StatusService,
		private commonService: CommonService,
	) {}

	getToken(): string {
        return this.commonService.token;
	}

	isLoggedIn() {
		if (this.commonService.token === "") return null;

		if (this.statusService.isOnline()) {
			return this.http
				.get<CheckTokenResponse>(`${this.apiUrl}portal/v0.1/user/checkToken`)
				.toPromise()
				.then((res: CheckTokenResponse|undefined) => {
					return res!.token;
				})
				.catch((err) => {
					return null;
				});
		} else {
			return this.commonService.token
		}
	}

	authenticate(key: string) {
		const date = new Date();
		return this.http.get(`${this.apiUrl}portal/v0.1/user/obtaintokenuser`).pipe(
			map((response: AuthResponse|any) => {
				const authResponse: AuthResponse = response;
				this.commonService.token = authResponse.token;
				this.commonService.companyName = authResponse.companyName;

				if (authResponse.isAdmin) {
					this.commonService.isAdmin = authResponse.isAdmin;
				}

				this.commonService.isSupport = authResponse.isSupport!;
				this.commonService.isInvoiceEnabled = authResponse.supportInvoices!;
				this.commonService.isRoutesEnabled = authResponse.routesEnabled;

				this.createUser();
				return authResponse;
			}),
		);
	}

	authenticateFirebase(email: string, password: string): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			this.afAuth
				.signInWithEmailAndPassword(email, password)
				.then((result: any) => {
				// .then((user: firebase.auth.UserCredential) => {
					let user = result.user;

					if (user) {
						user.getIdToken().then((token: any) => {
							window.location.href = `${environment.apiUrlHost}/auth/login?type=Other&tokenId=${token}&URL=${window.location.href}`;
						});
					}
				})
				.catch((err: any) => {
					if (err.code.includes('wrong-password') || err.code.includes('user-not-found')) {
						reject('Wrong username/password');
					}

					if (err.code.includes('too-many-requests')) {
						reject('Too many unsuccesful login attempts. Try again later.');
					}
				});
		});
	}

	passwordResetFirebase(email: string) {
		return this.afAuth.sendPasswordResetEmail(email);
	}

	checkFirebaseAuth() {
		this.afAuth.authState.subscribe(
			(user: any) => {
				if (user && localStorage.getItem('token')) {
					user.getIdToken().then((token: any) => {
						window.location.href = `${environment.apiUrlHost}/auth/login?type=Other&tokenId=${token}&URL=${window.location.href}`;
					});
				}
			},
			(err: any) => {},
		);
	}

	createUser() {
		let isGeneric = false;

		if (localStorage.getItem('isGeneric') == 'true') isGeneric = true;

		this.user = {
			companyName: this.commonService.companyName,

			isAdmin: this.commonService.isAdmin
		}
	}

	getUser(): User {
		return this.user!;
	}

	login(type: string, remember: boolean) {
		let params = `?type=${type}&URL=${window.location.href}&ad=`;


		window.location.href = `${environment.apiUrlHost}/auth/login${params}`;
	}

	logout() {
		localStorage.clear();

		window.location.href = `${environment.apiUrlHost}/auth/logout?site=nadmin&t=` + this.commonService.token;
	}

	kickSession() {
		localStorage.clear();
		this.loggedIn.next(false);
		this.router.navigate(['login'], { queryParamsHandling: "merge" });
	}

	
	authWithProvider(authProvider: string) {
		if(authProvider === "Google") return this.authLogin(new auth.GoogleAuthProvider);
		else if(authProvider === "Office") return this.authLogin(new auth.OAuthProvider('microsoft.com'));
		else return this.authLogin(new auth.OAuthProvider('microsoft.com'));
	}

	authLogin(provider: any) {
		return this.afAuth.signInWithPopup(provider)
		.then((result: any) => {
			let user = result.user;

			if (user) {
				user.getIdToken().then((token: any) => {
					window.location.href = `${environment.apiUrlHost}/auth/login?type=Other&tokenId=${token}&URL=${window.location.href}&ad=`;
				});
			}
		}).catch((error: any) => {
		  window.alert(error)
		})
	}
}
