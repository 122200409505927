import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';
import { ActionsService } from 'src/app/services/actions.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-b2c-enable-modal',
  templateUrl: './b2c-enable-modal.component.html',
  styleUrls: ['./b2c-enable-modal.component.scss']
})
export class B2cEnableModalComponent {
    unsubscriber$: Subject<any> = new Subject();

	idContact: string = "";

	isEnabled: boolean = false;
	message: string = "";
	showSuccessMessage: boolean = false;
	showErrorMessage: boolean = false;
	myContact: any;

	successMessage: Message[] = [];
	errorMessage: Message[] = [];

	constructor(
		private adminService: AdminService,
		private actionsService: ActionsService,
		private dialogRef: MatDialogRef<B2cEnableModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { contact: any },
	) {}

	ngOnInit(): void { 
		this.myContact = this.data.contact;
		
		this.isEnabled = this.myContact.isEnabled;	
		this.successMessage = [
            { severity: 'success', summary: 'Success', detail: '✔️ User access to B2C app updated' },
        ];
		this.errorMessage = [
            { severity: 'error', summary: 'Error', detail: '❌ There was an error' },
        ];
	}

    onSubmit() {		
		this.adminService.changeTouchAccess(this.myContact.contact_id, this.myContact.contact_email, this.myContact.customer_id).subscribe(
		{
			complete: () => {
				this.actionsService.set({ type: 'b2c-user-create', status: 'updated', message: '✔️ User access to B2C app updated'})
				this.dialogRef.close('success')
			},
			error: () =>  {
				this.actionsService.set({ type: 'notification', status: 'none', message: '❌ There was an error'});
				this.dialogRef.close('back');
			}
		});
	}

	onBack() {
		this.dialogRef.close("back");
	}
}
