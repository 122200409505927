<div class="create-item-card">
    <p-card>
        <h1>Add user</h1>
        <form id="fields-form">
            <!-- <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-user"></i>
                </span>
                <input type="text" pInputText placeholder="User ID" />
            </div> -->

            <label for="userid">{{ "ID" | translate }}</label>
            <input type="text" id="userid" name="userid"><br>
        
            <label for="email">{{ "Email" | translate }}</label>
            <input type="text" id="email" name="email"><br>
        
            <label for="password">{{ "Password" | translate }} ({{ "ONLY GENERIC USER" | translate }})</label>
            <input type="password" id="password" name="password"><br>
        
            <div class="user-access-switch">
                <div class="user-info">
                    <h5 style="font-weight: bold;">API</h5>
                    <p-inputSwitch [(ngModel)]="api" [ngModelOptions]="{ standalone: true }"></p-inputSwitch>
                </div>
                <div class="user-info">
                    <h5 style="font-weight: bold;">PORTAL</h5>
                    <p-inputSwitch [(ngModel)]="portal" [ngModelOptions]="{ standalone: true }"></p-inputSwitch>
                </div>
                <div class="user-info">
                    <h5 style="font-weight: bold;">ADMIN</h5>
                    <p-inputSwitch [(ngModel)]="admin" [ngModelOptions]="{ standalone: true }"></p-inputSwitch>
                </div>
            </div>

            <!-- <label for="portal">{{ "Portal" | translate }}</label>
            <input type="text" id="portal" name="portal"><br>
        
            <label for="api">API</label>
            <input type="text" id="api" name="api"><br>
        
            <label for="admin">{{ "Admin" | translate }}</label>
            <input type="text" id="admin" name="admin"><br> -->
            
            <input type="submit" value="Add item" (click)="addNewUser($event)">
        </form>
    </p-card>
</div>

<div class="confirmation-button">
    <p-toast></p-toast>
    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
</div>
