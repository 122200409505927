import { Component, OnInit } from '@angular/core';
import { ToolBarItem } from 'src/app/components/table-tool-bar/table-tool-bar.component';
import { AdminService } from 'src/app/services/admin.service';
import { DownloadService } from 'src/app/services/download.service';
import { ConfirmEventType, MessageService, ConfirmationService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-download-view',
  templateUrl: './download-view.component.html',
  styleUrls: ['./download-view.component.scss']
})
export class DownloadViewComponent implements OnInit{

    downloads: any[] = [];
    loadingDownloads: boolean = true;
    confirmationWindow: boolean = false;
    selectedDownload: any[] = [];
    canEdit: boolean = false;
    canDownload: boolean = false;
    apiKey: String = environment.apiPlenionKey;
	  apiPlenion: String = environment.apiPlenion;
    downloadToken: any = "";
    webViewLink: string = "";

    logs: any[] = [];
    rowExpandMode = "single";

    toolBarButtons: ToolBarItem[] = [];
	  toolBarButtonsMenu: ToolBarItem[] = [];
  
    constructor(
      private adminService: AdminService,
      private downloadService: DownloadService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
    ) {
      
    }

    async ngOnInit():  Promise<void> {
      this.loadDownloads();
      this.updateToolBar();
    }

    loadDownloads(): void {
      this.loadingDownloads = true;
      this.downloads = [];
      this.selectedDownload = [];

      this.downloadService.getDownloadFiles().subscribe((result: any) => {
          this.downloads = result.items;

          this.loadingDownloads = false;
      });

    }

    updateToolBar(): void {
      this.toolBarButtons = [
        {
          label: 'Enable/Disable',
          icon: 'create',
          align: 'left',
          disabled: !this.canEdit,
          action: () => {
            this.confirmationDownloads();
          },
        },

        {
          label: 'View Document',
          icon: 'visibility',
          align: 'left',
          disabled: !this.canDownload,
          action: async () => {
            this.openDownload(this.selectedDownload[0].itemId);
          },
        },

      ];
    }

    async openDownload (downloadItemId: string){
          this.downloadService.openFileDownload(downloadItemId).subscribe((result: any) => {
            this.webViewLink = result.webViewLink;
            window.open(this.webViewLink, "_blank");
          });
    }

    enableDisableDownload() {

      if (this.selectedDownload.length > 0) {

        this.selectedDownload.forEach(download => {
          this.downloadService.enableDisableDownload(download.fileId).subscribe((result: any) => {
          });
        });

        this.loadDownloads();
      }
    }
    
    onRowSelect(event: any) {		 
      this.selectedDownload.push(event.data);

      if(this.selectedDownload.length > 0) {
        this.canEdit = true;
      } 

      if (this.selectedDownload.length == 1) {
        this.canDownload = true;
      }else{
        this.canDownload = false;
      }

      this.updateToolBar();
    }
      
    onRowUnselect(event: any) {
      const index = this.selectedDownload.indexOf(event.data);
      if(index !== -1) {
        this.selectedDownload.splice(index, 1);
      }
      
      if(this.selectedDownload.length == 0) {
        this.canEdit = false;
      } 

      if (this.selectedDownload.length == 1) {
        this.canDownload = true;
      }else{
        this.canDownload = false;
      }
      
      this.updateToolBar();
    }	  
    
  
    confirmationDownloads() {
      this.confirmationService.confirm({
          message: 'Do you want to enable/disable the selected downloads?<br><b>' + '</b>',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            this.selectedDownload.forEach(async download => {
              this.downloadService.enableDisableDownload(download.itemId).subscribe({
                next: () => {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Enabled',
                    detail: 'File/s permissions changed',
                  });
                  this.downloads.find(downloadAux => downloadAux.itemId == download.itemId).isEnabled = !download.isEnabled;
                },

                error: () => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong. Try again.',
                  });
                }
              })
            },

            );
          },
          reject: (type: any) => {
            switch (type) {
                case ConfirmEventType.REJECT:
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Rejected',
                        detail: 'You have rejected',
                    });
                    break;
                case ConfirmEventType.CANCEL:
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Cancelled',
                        detail: 'You have cancelled',
                    });
                    break;
            }
        },       
      });
    }

    loadLogs(fileId: string) {
      this.downloadService.getFileLogDownload(fileId).subscribe((result: any) => {
        this.logs = result.items;
      });

    }
}
